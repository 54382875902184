/* eslint-disable react/prop-types */
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { lettreVoieList } from 'adapters/data.gouv/Fantoir.enum'
import { getFantoirVoies } from 'adapters/data.gouv/addressApi'
import { FantoirStreet } from 'adapters/data.gouv/type'
import { useEffect, useState } from 'react'
import { FormikProps } from 'formik'
import { CityAutocomplete } from 'components/Form'

interface FantoirAutocompleteProps {
  id: string
  label?: string
  form: FormikProps<any>
}

export const FantoirAutocomplete: React.FC<FantoirAutocompleteProps> = ({
  label,
  form
}) => {
  const [streetOptions, setStreetOptions] = useState<FantoirStreet[]>([])

  useEffect(() => {
    if (!form.values.fantoirCity) {
      setStreetOptions([])

      return undefined
    }

    form.setFieldValue('fantoirPostal', form.values.fantoirCity.postcode)
    ;(async () => {
      const response = await getFantoirVoies(form.values.fantoirCity.citycode)

      setStreetOptions(response)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.fantoirCity])

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <CityAutocomplete
          id="fantoirCity"
          label="Commune de résidence"
          form={form}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          id="fantoirPostal"
          label="Code postal"
          onChange={form.handleChange}
          value={form.values.fantoirPostal}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          onChange={e => form.setFieldValue('fantoirNumber', e.target.value)}
          type="number"
          id="fantoirNumber"
          label="Numéro voie"
          value={form.values.fantoirNumber}
        />
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel>Lettre</InputLabel>
          <Select
            onChange={e => form.setFieldValue('fantoirLetter', e.target.value)}
            value={form.values.fantoirLetter}
          >
            <MenuItem>
              <em> -- </em>
            </MenuItem>
            {Array.from(lettreVoieList.entries()).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          fullWidth
          disabled={form.values.fantoirCity === undefined}
          noOptionsText="Aucune voie trouvée"
          disablePortal
          value={form.values.fantoirStreet}
          options={streetOptions}
          getOptionKey={(option: FantoirStreet) => option.id}
          getOptionLabel={(option: FantoirStreet) =>
            `${option.natureVoie ?? ''} ${option.libelleVoie}`
          }
          renderInput={params => (
            <TextField
              {...params}
              label={label ?? 'Voie'}
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          onChange={(event, value) =>
            value && form.setFieldValue('fantoirStreet', value)
          }
        />
      </Grid>
    </Grid>
  )
}
