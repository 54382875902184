/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import {
  Paper,
  Snackbar,
  Alert,
  AlertTitle,
  Box,
  Container,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  FormGroup
} from '@mui/material'
import { useFormik } from 'formik'
import Keycloak from 'keycloak-js'
import * as yup from 'yup'
import { ThemeProvider, useTheme } from '@emotion/react'
import Unauthorized from 'components/Unauthorized/Unauthorized'
import Nav from 'components/Nav/Nav'
import { CountryInseeCode } from 'adapters/data.gouv/Insee.enum'
import {
  IBANInput,
  BICInput,
  CityAutocomplete,
  PhoneInput
} from 'components/Form'
import { DatePicker } from '@mui/x-date-pickers'
import PublishIcon from '@mui/icons-material/Publish'
import SaveIcon from '@mui/icons-material/Save'
import {
  createOrUpdatePrestationCustomer,
  hydratePrestationCustomerFormData,
  prestationCustomerInitialValues
} from './helpers'
import { UrssafPayerAutocomplete, FantoirAutocomplete } from 'components/Urssaf'
import { registerPrestationCustomer } from 'adapters/backend'
import { useKeycloakAuth } from 'hooks/useKeyCloakAuth.hook'
import { useMutation } from '@tanstack/react-query'
import LoadingNav from 'components/LoadingNav/LoadingNav'

const validationSchema = yup.object({})

export default function UrssafForm({ keycloak }: { keycloak?: Keycloak }) {
  useKeycloakAuth(keycloak)

  const theme = useTheme()
  const [openedSuccessSnackbar, setOpenedSuccessSnackbar] = useState<
    string | false
  >(false)
  const [openedFailureSnackbar, setOpenedFailureSnackbar] = useState<
    string | false
  >(false)
  const [externalId, setExternalId] = useState<string | null>(null)
  const [prestationCustomerLoaded, setPrestationCustomerLoaded] =
    useState<boolean>(false)

  const reloadForm = async () => {
    if (!externalId || !keycloak?.token) {
      return
    }

    const formData = await hydratePrestationCustomerFormData(externalId)

    if (formData === undefined) {
      formik.resetForm()
      setPrestationCustomerLoaded(false)

      return
    }

    setPrestationCustomerLoaded(true)
    formik.setValues(formData)
  }

  useEffect(() => {
    reloadForm()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalId])

  const handleSubmit = async (silent: boolean) => {
    if (!externalId) {
      return
    }

    try {
      await createOrUpdatePrestationCustomer(
        externalId,
        formik.values,
        prestationCustomerLoaded
      )
      setPrestationCustomerLoaded(true)
      if (!silent) {
        setOpenedSuccessSnackbar('Fiche sauvegardée')
      }
    } catch (error: any) {
      console.error(error)
      setOpenedFailureSnackbar(error?.message || 'Une erreur est survenue')
    }
  }

  const registerMutation = useMutation({
    mutationFn: () => registerPrestationCustomer(externalId as string),
    onSuccess: value => {
      setOpenedSuccessSnackbar("Client enregistré à l'URSSAF")
      reloadForm()
    },
    onError: (error: any) => {
      console.error(error)
      setOpenedFailureSnackbar(error?.response?.data?.message || 'Une erreur est survenue')
    }
  })

  const handleRegistration = async () => {
    await handleSubmit(true)
    if (externalId) {
      registerMutation.mutate()
    }
  }

  const formik = useFormik({
    initialValues: prestationCustomerInitialValues,
    validationSchema,
    onSubmit: () => handleSubmit(false)
  })

  return (
    (keycloak?.authenticated && keycloak.hasRealmRole('edit-urssaf') && (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
          <Nav keycloak={keycloak} />
          <Box
            component="main"
            sx={{
              backgroundColor: currentTheme =>
                currentTheme.palette.mode === 'light'
                  ? currentTheme.palette.grey[100]
                  : currentTheme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto'
            }}
          >
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={!!openedSuccessSnackbar}
              onClose={() => setOpenedSuccessSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenedSuccessSnackbar(false)}
                severity="success"
                variant="filled"
                sx={{ width: '100%', color: 'white' }}
              >
                <AlertTitle>Succès</AlertTitle>
                {openedSuccessSnackbar}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={!!openedFailureSnackbar}
              onClose={() => setOpenedFailureSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenedFailureSnackbar(false)}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
              >
                <AlertTitle>Erreur</AlertTitle>
                {openedFailureSnackbar}
              </Alert>
            </Snackbar>
            <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography
                  variant="h4"
                  marginTop={0}
                  color={'primary'}
                  gutterBottom
                >
                  Formulaire URSSAF - crédit d'impôt
                </Typography>
                <UrssafPayerAutocomplete
                  form={formik}
                  keycloak={keycloak}
                  onChange={setExternalId}
                />
                {externalId && (
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container>
                      <Grid item container xs={12} spacing={2} paddingTop={2}>
                        <Grid item xs={7}>
                          {formik.values.apiStatus === 'REGISTERED' && (
                            <Alert sx={{ mt: 2 }} severity="success">
                              La fiche a été acceptée par l'URSSAF. Le client
                              doit maintenant la valider dans son espace.
                            </Alert>
                          )}
                          {formik.values.apiStatus === 'INIT' && (
                            <Alert sx={{ mt: 2 }} severity="info">
                              La fiche est enregistrée mais pas encore publiée
                            </Alert>
                          )}
                          {formik.values.apiStatus === 'OK' && (
                            <Alert sx={{ mt: 2 }} severity="success">
                              Le client a validé la fiche, le paiement est
                              possible
                            </Alert>
                          )}
                          {formik.values.apiStatus === 'KO' && (
                            <Alert sx={{ mt: 2 }} severity="warning">
                              Le client a bloqué sa fiche. Paiement impossible
                            </Alert>
                          )}
                        </Grid>
                        <Grid item xs={7}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  id="enabled"
                                  checked={formik.values.enabled}
                                  onChange={e => {
                                    formik.setFieldValue(
                                      'enabled',
                                      e.target.checked
                                    )
                                  }}
                                  color="warning"
                                />
                              }
                              label={
                                formik.values.enabled
                                  ? 'Paiement URSSAF activé'
                                  : 'Paiement URSSAF bloqué'
                              }
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            marginTop={0}
                            color={'primary'}
                          >
                            Identité du contribuable
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item xs={2}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Civilité
                              </InputLabel>
                              <Select
                                value={formik.values.gender}
                                id="gender"
                                onChange={e => {
                                  formik.setFieldValue('gender', e.target.value)
                                }}
                              >
                                <MenuItem value={1}>M.</MenuItem>
                                <MenuItem value={2}>Mme</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            fullWidth
                            id="firstNames"
                            name="firstNames"
                            type="text"
                            label={
                              "Prénom(s) tel qu'indiqué sur la pièce d'identité"
                            }
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={formik.values.firstNames}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.firstNames &&
                              Boolean(formik.errors.firstNames)
                            }
                            helperText={
                              formik.touched.firstNames &&
                              formik.errors.firstNames
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth>
                            <DatePicker
                              label="Date de naissance"
                              onChange={value => {
                                if (value) {
                                  value.setHours(12)
                                }

                                return formik.setFieldValue('birthDate', value)
                              }}
                              value={formik.values.birthDate}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="birthName"
                            name="birthName"
                            type="text"
                            label={'Nom de naissance'}
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={formik.values.birthName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="lastName"
                            name="lastName"
                            type="text"
                            label={"Nom d'usage (si différent)"}
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel>Pays de naissance</InputLabel>
                            <Select
                              value={formik.values.birthCountry}
                              id="birthCountry"
                              onChange={e => {
                                formik.setFieldValue(
                                  'birthCountry',
                                  e.target.value
                                )
                              }}
                            >
                              {Array.from(CountryInseeCode.entries()).map(
                                ([key, value]) => (
                                  <MenuItem key={key} value={key}>
                                    {value}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <CityAutocomplete
                            id="birthCity"
                            label="Commune de naissance"
                            disabled={formik.values.birthCountry !== '99100'}
                            form={formik}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            marginTop={0}
                            color={'primary'}
                          >
                            Informations de contact
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="email"
                            name="email"
                            type="email"
                            label={'E-mail (compte particuliers.urssaf.fr)'}
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <PhoneInput
                            id="mobilePhone"
                            fullWidth
                            label="Numero de téléphone en 06 ou 07"
                            form={formik}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            marginTop={0}
                            color={'primary'}
                          >
                            Adresse de la résidence principale
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FantoirAutocomplete
                            id="fantoirAddress"
                            form={formik}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            marginTop={0}
                            color={'primary'}
                          >
                            Informations bancaires
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="accountHolder"
                            name="accountHolder"
                            type="text"
                            label={'Titulaire du compte'}
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={formik.values.accountHolder}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <IBANInput form={formik} id="iban" />
                        </Grid>
                        <Grid item xs={4} sx={{ mb: 4 }}>
                          <BICInput form={formik} id="bic" />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 3 }}>
                      <Button
                        type="submit"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        sx={{ mr: 3 }}
                      >
                        Enregister la fiche
                      </Button>
                      <Button
                        color="success"
                        startIcon={<PublishIcon />}
                        variant="contained"
                        disabled={!prestationCustomerLoaded}
                        onClick={handleRegistration}
                      >
                        Publier à l'URSSAF
                      </Button>
                    </Grid>
                  </form>
                )}
              </Paper>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    )) || (keycloak?.authenticated && !keycloak.hasRealmRole('edit-urssaf') ? (
      <Unauthorized />
    ) : (
      <LoadingNav keycloak={keycloak} />
    ))
  )
}
