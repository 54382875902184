import Autocomplete from '@mui/material/Autocomplete'
import { InputGroup } from 'components/Calendar/molecules'
import { FieldProps } from 'formik'
import { useFormikInput } from 'hooks/useFormikInput'
import React, { useEffect } from 'react'
import { CONTRACT_TYPE_FR, ScheduleContractType } from 'types/enum'
import { StyledAutocompleteTextField } from './ContractTypePicker.style'

interface ContractTypePickerProps {
  label?: string
  withError?: boolean
  required?: boolean
  contractType?: ScheduleContractType
}

export const ContractTypePicker: React.FC<
  FieldProps & ContractTypePickerProps
> = ({ field, form, label, withError, required, contractType }) => {
  const { value, hasError, error } = useFormikInput<ScheduleContractType>(
    form,
    field
  )

  useEffect(() => {
    if (contractType !== undefined && value !== contractType) {
      form.setFieldValue(field.name, contractType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractType])

  return (
    <>
      <InputGroup
        error={error}
        label={label}
        withError={withError}
        required={required}
      >
        <Autocomplete
          onChange={(_event, value) => {
            // Only update field value if value is different from contractType prop
            if (value !== contractType) {
              form.setFieldValue(field.name, value)
            }
          }}
          getOptionLabel={(option: ScheduleContractType) =>
            CONTRACT_TYPE_FR[option]
          }
          options={Object.values(ScheduleContractType)}
          value={value ?? null}
          renderInput={params => (
            <StyledAutocompleteTextField hasError={hasError} {...params} />
          )}
        />
      </InputGroup>
    </>
  )
}
