import axios from 'axios'
import { DataGouvMunicipality } from './type'

export const searchAddress = async (address: string) => {
  try {
    const response = await axios.get(
      'https://api-adresse.data.gouv.fr/search',
      { params: { q: address } }
    )

    return response.data.features
  } catch (e) {
    console.log(e)

    return []
  }
}

export const searchCity = async (
  search: string
): Promise<DataGouvMunicipality[]> => {
  try {
    const response = await axios.get(
      'https://api-adresse.data.gouv.fr/search',
      {
        params: { q: search, type: 'municipality', autocomplete: 1 }
      }
    )

    return response.data.features.map((feature: any) => ({
      label: feature.properties.label,
      id: feature.properties.id,
      type: feature.properties.type,
      name: feature.properties.name,
      postcode: feature.properties.postcode,
      citycode: feature.properties.citycode,
      x: feature.properties.x,
      y: feature.properties.y,
      population: feature.properties.population,
      city: feature.properties.city,
      context: feature.properties.context,
      importance: feature.properties.importance,
      municipality: feature.properties.municipality
    }))
  } catch (e) {
    console.log(e)

    return []
  }
}

export const getFantoirVoies = async (inseeCode: string) => {
  try {
    const response = await axios.get(
      `https://plateforme.adresse.data.gouv.fr/api-fantoir/communes/${inseeCode}/voies`
    )

    console.log(
      `https://plateforme.adresse.data.gouv.fr/api-fantoir/communes/${inseeCode}/voies`
    )
    console.log(response)

    return response.data
  } catch (e) {
    console.log(e)

    return []
  }
}
