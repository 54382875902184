import { EventInput } from '@fullcalendar/react'
import DeleteIcon from '@mui/icons-material/Delete'
import { FC } from 'react'
import { formatDateInHourUTC } from 'utils/tools'
import { Container, DeleteButton, Hours, Title } from './CalendarEvent.style'
import { ContractType } from 'types/enum'

type Props = {
  event: EventInput
  onDelete?: (id: string) => void
}

export const CalendarEvent: FC<Props> = ({ event, onDelete }) => {
  return (
    <Container>
      <Hours>
        {formatDateInHourUTC(event.startStr)} -{' '}
        {formatDateInHourUTC(event.endStr)}
      </Hours>
      {event.title && <Title>{event.title}</Title>}
      {event.extendedProps?.contractType && (
        <Title>
          {event.extendedProps.contractType === ContractType.LIBERAL
            ? 'Libéral'
            : 'CESU'}
        </Title>
      )}
      {onDelete && (
        <DeleteButton onClick={() => onDelete(event.id as string)}>
          <DeleteIcon sx={{ color: '#666' }} fontSize="small" />
        </DeleteButton>
      )}
    </Container>
  )
}
