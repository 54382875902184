import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createQuote,
  duplicateQuote,
  getAllQuotesFromPipedriveId,
  updateQuote,
  validateQuote
} from 'adapters/backend'
import {
  getAllPipedriveLeads,
  getPipedriveLeadDetails
} from 'adapters/backend/pipedrive'
import { toLeadSelection, toQuoteSelection } from 'pages/Quote/helpers'
import { UpdateQuoteDto } from 'types/dto'

export const useAvailablePipedriveLeads = () =>
  useQuery({
    queryKey: ['available-leads'],
    queryFn: () => getAllPipedriveLeads(),
    select: data => data.map(toLeadSelection)
  })

export const useGetLeadDetails = (leadId: string | undefined) =>
  useQuery({
    queryKey: ['leads', leadId],
    queryFn: () => getPipedriveLeadDetails(leadId as string),
    enabled: !!leadId
  })

export const useGetAllQuotes = (leadId: string | undefined) =>
  useQuery({
    queryKey: ['quotes', leadId],
    queryFn: () => getAllQuotesFromPipedriveId(leadId as string),
    select: data => data.map(toQuoteSelection),
    enabled: !!leadId
  })

export const useValidateQuoteMutation = (leadId: string | undefined) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (quoteId: string) => validateQuote(quoteId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['quotes', leadId] })
    }
  })
}

export const useDuplicateQuoteMutation = (leadId: string | undefined) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (quoteId: string) => duplicateQuote(quoteId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['quotes', leadId] })
    }
  })
}

export const useNewQuoteMutation = (leadId: string | undefined) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (pipedriveId: string) => createQuote(pipedriveId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['quotes', leadId] })
    }
  })
}

export const useUpdateQuoteMutation = (leadId: string | undefined) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (update: { id: string; updateQuoteDto: UpdateQuoteDto }) =>
      updateQuote({ ...update }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['quotes', leadId] })
    }
  })
}
