import { Button } from '@mui/base/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

type Props = {
  open: boolean
  title: string
  text: string
  onAcknowledge: () => void
}

export const AlertDialog = ({ open, title, text, onAcknowledge }: Props) => {
  return (
    <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAcknowledge} autoFocus>
          J&apos;ai compris
        </Button>
      </DialogActions>
    </Dialog>
  )
}
