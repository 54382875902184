import { TextField } from '@mui/material'

export default function FormikTextField({
  name,
  label,
  formik,
  multiline = false
}: {
  name: string
  label: string
  formik: any
  multiline?: boolean
}) {
  return (
    <TextField
      fullWidth
      id={name}
      name={name}
      label={label}
      type="text"
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      multiline={multiline}
    />
  )
}
