import {
  createSchedule,
  deleteSchedule,
  getRealScheduleEstimatesByAnnounce,
  getRealScheduleEstimatesByApplication,
  getRealScheduleEstimatesByBeneficiary,
  getRealScheduleEstimatesByLead,
  getRealScheduleEstimatesByMission,
  getRealScheduleEstimatesByQuote,
  getSchedules
} from 'adapters/backend'
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { CreateScheduleDto, RealScheduleEstimateDto } from 'types/dto'
import { EntityType, RealScheduleEstimate, Schedule } from 'types/entity'
import { parseRealScheduleEstimateDto } from 'utils/parsers'
import { useLocation } from 'react-router-dom'

export const SCHEDULE_BASE_KEY = 'schedules'

export const useDeleteSchedule = (): UseMutationResult<
  void,
  unknown,
  string,
  void
> => {
  const queryClient = useQueryClient()
  const { search } = useLocation()
  const apiKey = new URLSearchParams(search).get('apikey')

  return useMutation({
    mutationFn: (id: string) => deleteSchedule(id, apiKey || ''),
    onMutate: async (id: string) => {
      await queryClient.cancelQueries({ queryKey: [SCHEDULE_BASE_KEY] })
      queryClient.setQueryData(
        [SCHEDULE_BASE_KEY],
        (old: Schedule[] | undefined) =>
          old === undefined ? [] : old.filter(schedule => schedule.id !== id)
      )
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [SCHEDULE_BASE_KEY] }),
    onError: () =>
      queryClient.invalidateQueries({ queryKey: [SCHEDULE_BASE_KEY] })
  })
}

export const useCreateScheduleMutation = () => {
  const queryClient = useQueryClient()
  const { search } = useLocation()
  const apiKey = new URLSearchParams(search).get('apikey')

  return useMutation({
    mutationFn: ({
      entityId,
      entityType,
      createScheduleDto
    }: {
      entityId: string
      entityType: EntityType
      createScheduleDto: CreateScheduleDto
    }) => createSchedule(entityId, entityType, createScheduleDto, apiKey || ''),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SCHEDULE_BASE_KEY] })
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: [SCHEDULE_BASE_KEY] })
    }
  })
}

export const getScheduleEstimateQueryKey = (
  id: string | undefined,
  startDate: string
) => {
  const key = `scheduleEstimates_${id}_${startDate}`

  return key
}

interface UseRealScheduleEstimatesResult {
  data: RealScheduleEstimate[] | undefined
  error: unknown
  isError: boolean
  isLoading: boolean
  refetch: () => void
}

export const useSchedules = (entityId: string, entityType: EntityType) => {
  const { search } = useLocation()
  const apiKey = new URLSearchParams(search).get('apikey')

  return useQuery({
    queryKey: [SCHEDULE_BASE_KEY, entityType, entityId],
    staleTime: 0,
    queryFn: async () => getSchedules(entityId, entityType, apiKey || '')
  })
}

export const useRealScheduleWithEstimates = (
  id: string | undefined,
  startDate: string,
  endDate: string,
  entity: EntityType
  // eslint-disable-next-line max-params
): UseRealScheduleEstimatesResult => {
  return useQuery({
    queryKey: [SCHEDULE_BASE_KEY, getScheduleEstimateQueryKey(id, startDate)],
    staleTime: 0,
    queryFn: async () => {
      let items: RealScheduleEstimateDto[] = []

      if (!id) {
        return items
      }

      switch (entity) {
        case 'announce':
          items = await getRealScheduleEstimatesByAnnounce(
            id,
            startDate,
            endDate
          )
          break
        case 'quote':
          items = await getRealScheduleEstimatesByQuote(id, startDate, endDate)
          break
        case 'beneficiary':
          items = await getRealScheduleEstimatesByBeneficiary(
            id,
            startDate,
            endDate
          )
          break
        case 'mission':
          items = await getRealScheduleEstimatesByMission(
            id,
            startDate,
            endDate
          )
          break
        case 'application':
          items = await getRealScheduleEstimatesByApplication(
            id,
            startDate,
            endDate
          )
          break
        case 'lead':
          items = await getRealScheduleEstimatesByLead(id, startDate, endDate)
          break
      }

      const formatedItems = items.map(item =>
        parseRealScheduleEstimateDto(item)
      )

      return formatedItems
    },
    placeholderData: []
  })
}
