import { useEffect, useState } from 'react'
import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  Button,
  TextField,
  Snackbar,
  Alert,
  AlertTitle
} from '@mui/material'
import { useFormik } from 'formik'
import Keycloak from 'keycloak-js'
import * as yup from 'yup'
import { ThemeProvider, useTheme } from '@emotion/react'
import { searchAddress } from 'adapters/data.gouv/addressApi'
import { postFamilyLead } from 'utils/backendApi'
import AddressAutocomplete from 'components/Contact/AddressAutocomplete'
import BeneficiaryFields from 'components/Contact/BeneficiaryFields'
import FormikTextField from 'components/Contact/FormikTextField'
import Unauthorized from 'components/Unauthorized/Unauthorized'
import {
  searchPipedriveBTOBAS,
  searchPipedriveBTOBOrgs
} from 'adapters/backend/pipedrive'
import { PipedriveOrgDto, PipedrivePersonDto } from 'types/dto'
import OrgAutocomplete from 'components/Contact/OrgAutocomplete'
import ASAutocomplete from 'components/Contact/ASAutocomplete'

const validationSchema = yup.object({
  referralEmail: yup
    .string()
    .email('Merci de renseigner un email valide')
    .required("L'email référent est requis"),
  referralLink: yup
    .string()
    .required('Le lien avec le bénéficiaire est requis'),
  address: yup.string().required("L'adresse est requise"),
  firstNameBenef: yup.string().required('Le prénom du bénéficiaire est requis'),
  lastNameBenef: yup
    .string()
    .required('Le nom de famille du bénéficiaire est requis'),
  // Optional fields
  birthdayBenef: yup.string().optional(),
  financialAid: yup.string().optional(),
  daysOfIntervention: yup.array().of(yup.string()).optional(),
  hourVolume: yup.number().optional(),
  additionalNeeds: yup.array().of(yup.string()).optional(),
  services: yup.array().of(yup.string()).optional(),
  referralFirstName: yup.string().optional(),
  referralLastName: yup.string().optional(),
  startDate: yup.string().optional(),
  additionalInformation: yup.string().optional(),
  referralInstitutionName: yup.string().optional(),
  referralASName: yup.string().optional()
})

export default function TSForm({ keycloak }: { keycloak?: Keycloak }) {
  const searchParams = new URLSearchParams(window.location.search)
  const [addresses, setAddresses] = useState([])
  const [orgs, setOrgs] = useState<PipedriveOrgDto[]>([])
  const [asList, setAsList] = useState<PipedrivePersonDto[]>([])
  const [selectedAddress, setSelectedAddress] = useState<any>({})
  const [selectedOrg, setSelectedOrg] = useState<any>({})
  const [selectedAS, setSelectedAS] = useState<any>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  const theme = useTheme()
  const [urlEmail, setUrlEmail] = useState('')
  const [openedSuccessSnackbar, setOpenedSuccessSnackbar] = useState(false)
  const [openedFailureSnackbar, setOpenedFailureSnackbar] = useState(false)
  const [establishmentName, setEstablishmentName] = useState('')
  const [establishmentId, setEstablishmentId] = useState('')
  const [asId, setAsId] = useState('')
  const [referralEmail, setReferralEmail] = useState('')
  const [referralFirstName, setReferralFirstName] = useState('')
  const [referralLastName, setReferralLastName] = useState('')
  const [referralPhone, setReferralPhone] = useState('')

  useEffect(() => {
    if (!keycloak) {
      return
    }

    if (!keycloak.authenticated) {
      window.location.assign(keycloak.createLoginUrl())
    }

    keycloak
      .loadUserProfile()
      .then((profile: any) => {
        if (profile?.attributes && profile?.attributes?.establishment?.length) {
          setEstablishmentName(profile?.attributes?.establishment[0])
        }
        if (
          profile?.attributes &&
          profile?.attributes?.establishment_id?.length
        ) {
          setEstablishmentId(profile?.attributes?.establishment_id[0])
        }
        if (profile?.attributes && profile?.attributes?.as_id?.length) {
          setAsId(profile?.attributes?.as_id[0])
        }
        if (profile?.attributes && profile?.attributes?.as_first_name?.length) {
          setReferralFirstName(profile?.attributes?.as_first_name[0])
        }
        if (profile?.attributes && profile?.attributes?.as_last_name?.length) {
          setReferralLastName(profile?.attributes?.as_last_name[0])
        }
        if (profile?.attributes && profile?.attributes?.as_phone?.length) {
          setReferralPhone(profile?.attributes?.as_phone[0])
        }
        setReferralEmail(profile.email)
        formik.setFieldValue('referralEmail', profile.email)
      })
      .catch()
  }, [keycloak])

  const getASEmail = (as: any) => (as?.email?.length > 0 ? as?.email[0] : null)
  const getASPhone = (as: any) => (as?.phone?.length > 0 ? as?.phone[0] : null)

  useEffect(() => {
    if (!searchParams.has('email')) {
      return
    }

    yup
      .string()
      .email()
      .validate(searchParams.get('email'))
      .then(() => {
        setUrlEmail(searchParams.get('email') || '')
        formik.setFieldValue('referralEmail', searchParams.get('email') || '')
      })
      .catch()
  }, [])

  const formik = useFormik({
    initialValues: {
      referralEmail: urlEmail,
      referralPhone: '',
      referralLink: 'other',
      address: '',
      source: '',
      firstNameBenef: '',
      lastNameBenef: '',
      birthdayBenef: '1940-01-01',
      financialAid: '',
      daysOfIntervention: [],
      hourVolume: 0,
      services: [],
      additionalNeeds: [],
      referralFirstName: undefined,
      referralLastName: undefined,
      startDate: undefined,
      additionalInformation: '',
      referralInstitutionName: '',
      referralInstitutionId: '',
      referralASName: '',
      referralASId: ''
    },
    validationSchema,
    onSubmit: values => {
      const defaultAddress = addresses.length > 0 ? addresses[0] : ({} as any)
      const addressToSubmit =
        Object.keys(selectedAddress).length > 0
          ? selectedAddress
          : defaultAddress

      setIsSubmitting(true)
      const body = {
        referral: {
          email:
            getASEmail(selectedAS) || referralEmail || values.referralEmail,
          phone:
            getASPhone(selectedAS) || referralPhone || values.referralPhone,
          firstName:
            selectedAS.first_name ||
            referralFirstName ||
            values.referralFirstName,
          lastName:
            selectedAS.last_name || referralLastName || values.referralLastName
        },
        beneficiary: {
          firstName: values.firstNameBenef,
          lastName: values.lastNameBenef,
          address: {
            gpsLng: addressToSubmit?.geometry?.coordinates[0],
            gpsLat: addressToSubmit?.geometry?.coordinates[1],
            city: addressToSubmit?.properties?.city,
            postCode: addressToSubmit?.properties?.postcode,
            address: addressToSubmit?.properties?.label
          },
          birthday: values.birthdayBenef
        },
        financialAid: values.financialAid,
        daysOfIntervention: values.daysOfIntervention,
        hourVolume: values.hourVolume,
        additionalNeeds: values.additionalNeeds,
        startDate: values.startDate,
        additionalInformation: values.additionalInformation,
        services: values.services,
        referralInstitutionName: establishmentName || selectedOrg?.name,
        referralInstitutionId: String(establishmentId || selectedOrg?.id),
        referralAsId: String(asId || selectedAS?.id)
      }

      postFamilyLead(body, 'ts')
        .then(() => {
          setIsSubmitting(false)
          setOpenedSuccessSnackbar(true)
          formik.resetForm()
          setAddresses([])
        })
        .catch(() => {
          setIsSubmitting(false)
          setOpenedFailureSnackbar(true)
        })
    }
  })

  useEffect(() => {
    if (!formik.values.address || formik.values.address.length <= 3) {
      return
    }

    searchAddress(formik.values.address).then((addresses): void => {
      setAddresses(addresses)
    })
  }, [formik.values.address])

  useEffect(() => {
    if (
      !keycloak?.authenticated ||
      !keycloak.hasRealmRole('edit-lead') ||
      !keycloak.token ||
      orgs.length > 0
    ) {
      return
    }

    searchPipedriveBTOBOrgs(keycloak.token).then((orgs): void => {
      setOrgs(orgs)
    })
  }, [keycloak, orgs.length])

  useEffect(() => {
    if (!selectedOrg.id) {
      return
    }

    setSelectedAS(undefined)

    searchPipedriveBTOBAS(selectedOrg.id).then((asList): void => {
      setAsList(asList)
    })
  }, [selectedOrg])

  const filterOptions = (options: any[]) => options

  return (
    (keycloak?.authenticated &&
      (keycloak.hasRealmRole('social_worker') ||
        keycloak.hasRealmRole('edit-lead')) && (
        <ThemeProvider theme={theme}>
          <Container style={{ padding: '20px' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={openedSuccessSnackbar}
              onClose={() => setOpenedSuccessSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenedSuccessSnackbar(false)}
                severity="success"
                variant="filled"
                sx={{ width: '100%', color: 'white' }}
              >
                <AlertTitle>Succès</AlertTitle>
                Votre demande de contact a bien été envoyée
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={openedFailureSnackbar}
              onClose={() => setOpenedFailureSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenedFailureSnackbar(false)}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
              >
                <AlertTitle>Erreur</AlertTitle>
                Une erreur est survenue
              </Alert>
            </Snackbar>
            <Grid container>
              <Paper style={{ padding: '20px', width: '100%' }}>
                <Grid container direction={'column'} alignItems={'center'}>
                  <img
                    src="./img/logo-click-and-care-color.png"
                    style={{
                      height: isSm ? '50px' : '150px',
                      marginBottom: '20px'
                    }}
                  />
                  <Typography
                    component="h2"
                    variant={isSm ? 'h5' : 'h3'}
                    color="primary"
                    fontWeight={'bold'}
                    align="center"
                    display={'inline-block'}
                    paddingLeft={'10px'}
                    paddingRight={'10px'}
                    marginBottom={'25px'}
                  >
                    Formulaire d'inscription de votre bénéficiaire
                  </Typography>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={0} direction={'column'}>
                      <BeneficiaryFields
                        formik={formik}
                        optionalFields={true}
                      />
                      <Grid item xs={12} style={{ marginBottom: '20px' }}>
                        <AddressAutocomplete
                          addresses={addresses}
                          setSelectedAddress={setSelectedAddress}
                          filterOptions={filterOptions}
                          formik={formik}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: '20px' }}>
                        <TextField
                          fullWidth
                          id="startDate"
                          name="startDate"
                          type="date"
                          label={'Date de début estimative'}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={formik.values.startDate}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.startDate &&
                            Boolean(formik.errors.startDate)
                          }
                          helperText={
                            formik.touched.startDate && formik.errors.startDate
                          }
                        />
                      </Grid>
                      {keycloak.hasRealmRole('edit-lead') && (
                        <>
                          <Grid item xs={12} style={{ marginBottom: '20px' }}>
                            <OrgAutocomplete
                              orgs={orgs}
                              setSelectedOrg={setSelectedOrg}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginBottom: '20px' }}>
                            <ASAutocomplete
                              asList={asList}
                              setSelectedAS={setSelectedAS}
                              selectedAS={selectedAS}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} style={{ marginBottom: '20px' }}>
                        <FormikTextField
                          name="additionalInformation"
                          label="Notes"
                          formik={formik}
                          multiline={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          color="secondary"
                          variant="contained"
                          fullWidth
                          type="submit"
                          size={'large'}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress color="secondary" />
                          ) : (
                            'Envoyer'
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Paper>
            </Grid>
          </Container>
        </ThemeProvider>
      )) || <Unauthorized />
  )
}
