import styled from '@emotion/styled'
import { StyledTextField } from 'components/Calendar/atoms'

export const StyledAutocompleteTextField = styled(StyledTextField)`
  input {
    padding-top: 1px !important;
    padding-bottom: 0px !important;
  }

  svg {
    color: #387f95;
  }
`
