// Keep `FullCalendar` import before `@fullcalendar` plugins to avoid error
// Attention pour que les plugins fonctionnent il doivent être exactement du même version que @fullcalendar/react
import FullCalendar, { CalendarOptions } from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import { CalendarEvent } from 'components/Calendar/atoms'
import { FC } from 'react'
import { convertSchedulesToEvents } from 'utils/schedules'
import { Schedule } from 'types/entity'

export interface CalendarProps extends CalendarOptions {
  schedules?: Schedule[] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventContentProps?: any
  setStartDate: (start: Date) => void
}

export const Calendar: FC<CalendarProps> = ({
  schedules,
  eventContentProps,
  setStartDate,
  initialDate,
  ...otherProps
}) => {
  return (
    <FullCalendar
      plugins={[rrulePlugin, timeGridPlugin, interactionPlugin]}
      initialView="timeGridWeek"
      allDaySlot={false}
      firstDay={1}
      locale="fr"
      height={'82vh'}
      datesSet={({ start }) => setStartDate(start)}
      buttonText={{ today: "Aujourd'hui" }}
      events={convertSchedulesToEvents(schedules, initialDate as Date)}
      eventContent={content => (
        <CalendarEvent event={content.event} {...eventContentProps} />
      )}
      editable={false}
      selectable={false}
      timeZone="UTC"
      initialDate={initialDate}
      {...otherProps}
    />
  )
}
