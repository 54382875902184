import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Title from '../Title/Title'
import { Autocomplete, Box, Chip, IconButton, TextField } from '@mui/material'
import moment from 'moment'
import {
  getEstablishments,
  getEstablishmentsByCareGiverId,
  getVacanciesByEstablishmentId,
  searchCareGivers
} from '../../adapters/microservices/instititions'
import { OutlineButton } from '../Button/Button.style'
import Matchings from '../Matchings/Matchings'
import { KeyboardArrowRight, Replay } from '@mui/icons-material'
import Keycloak from 'keycloak-js'

const statusMap = new Map([['INITIAL', 'Non pourvu']])

const QUALIFICATIONS_SKILLS = new Map([
  ['AS', ['FFAS', 'ASD', 'AS']],
  ['ASH', ['ASH', 'FFAS']]
])

const getEstablishmentAutocomplete = (establishment: any) =>
  `${establishment?.holding?.name || ''} ${establishment?.name || ''} ${
    `(${establishment?.address?.city})` || ''
  }`.trim()

export default function Vacancies({ keycloak }: { keycloak: Keycloak }) {
  const [vacancies, setVacancies] = useState<any[]>([])
  const [establishment, setEstablishment] = useState<any>({})

  const [establishments, setEstablishments] = useState<any[]>([])
  const [drawerOpened, setDrawerOpened] = useState(false)

  const [searchNumber, setSearchNumber] = useState('')
  const [careGivers, setCareGivers] = useState<any[]>([])
  const [careGiver, setCareGiver] = useState<any>({})

  const [vacancy, setVacancy] = useState<any>([])

  const reset = () => {
    setCareGiver(undefined)
    setEstablishment(undefined)
    setSearchNumber('')
    setVacancies([])
    setCareGivers([])
    setVacancy(undefined)

    getEstablishments()
      .then((data): any => {
        setEstablishments(data)
      })
      .catch(err => console.error(err))
  }

  const reloadVacancies = () => {
    if (!establishment?.id) {
      return
    }

    setVacancies([])

    getVacanciesByEstablishmentId(establishment.id)
      .then(vacanciesItem => {
        if (careGiver) {
          const filteredVacancies = vacanciesItem.filter(
            vac =>
              vac.qualification === careGiver.qualification ||
              (QUALIFICATIONS_SKILLS.has(vac.qualification) &&
                QUALIFICATIONS_SKILLS.get(vac.qualification)?.find(
                  qualif => qualif === careGiver.qualification
                ))
          )

          setVacancies(filteredVacancies)
        } else {
          setVacancies(vacanciesItem)
        }
      })
      .catch(console.error)
  }

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    reloadVacancies()
  }, [establishment])

  useEffect(() => {
    if (searchNumber.length < 3) {
      return
    }

    searchCareGivers(searchNumber).then(cares => setCareGivers(cares))
  }, [searchNumber])

  useEffect(() => {
    if (!careGiver?.id) {
      return
    }

    getEstablishmentsByCareGiverId(careGiver.id).then(establishments => {
      setEstablishments(establishments)
      setEstablishment(undefined)
      setVacancies([])
    })
  }, [careGiver])

  return (
    <React.Fragment>
      <Title>Besoins</Title>
      <Matchings
        vacancy={vacancy}
        drawerOpened={drawerOpened}
        setDrawerOpened={setDrawerOpened}
        keycloak={keycloak}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
          justifyItems: 'start',
          marginTop: '10px'
        }}
      >
        <Autocomplete
          style={{ minWidth: '35%' }}
          value={
            establishment ? getEstablishmentAutocomplete(establishment) : ''
          }
          options={establishments.map(establishment =>
            getEstablishmentAutocomplete(establishment)
          )}
          onChange={(_, value) =>
            setEstablishment(
              establishments.find(
                establishment =>
                  getEstablishmentAutocomplete(establishment) === value
              )
            )
          }
          renderInput={params => (
            <TextField
              {...params}
              label={`${establishments.length || ''} Etablissement${
                establishments.length > 1 ? 's' : ''
              }`.trim()}
              variant="standard"
            />
          )}
        />
        <TextField
          style={{ minWidth: '10%' }}
          label="Numéro de téléphone"
          type="tel"
          variant="standard"
          placeholder="Numéro de téléphone"
          value={searchNumber}
          onChange={event => setSearchNumber(event.target.value)}
        />
        <Autocomplete
          style={{ minWidth: '20%' }}
          value={
            careGiver
              ? `${careGiver?.firstName || ''} ${
                  careGiver?.lastName || ''
                }`.trim()
              : ''
          }
          options={careGivers.map(care =>
            `${care?.firstName || ''} ${care?.lastName || ''}`.trim()
          )}
          onChange={(_, value) =>
            setCareGiver(
              careGivers.find(
                ({ firstName, lastName }) =>
                  `${firstName || ''} ${lastName || ''}`.trim() === value
              )
            )
          }
          renderInput={params => (
            <TextField
              {...params}
              label={`${careGivers.length || ''} Intervenant${
                careGivers.length > 1 ? 's' : ''
              }`.trim()}
              variant="standard"
            />
          )}
        />
        <OutlineButton style={{ minWidth: '20%' }} onClick={() => reset()}>
          Réinitialiser les filtres
        </OutlineButton>
        <IconButton onClick={() => reloadVacancies()}>
          <Replay></Replay>
        </IconButton>
      </Box>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Profil</TableCell>
            <TableCell>Score Max</TableCell>
            <TableCell>Nombre d'intervenants trouvés</TableCell>
            <TableCell>Statut</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vacancies.map(vac => (
            <TableRow key={vac.id} data-id={vac.id}>
              <TableCell>
                {moment(vac.startDate).format('DD/MM/YYYY HH:mm')}-
                {moment(vac.endDate).format('HH:mm')}
              </TableCell>
              <TableCell>{<Chip label={vac.qualification} /> || ''}</TableCell>
              <TableCell>
                {(vac.matchings.length && vac.matchings[0].score) || ''}
              </TableCell>
              <TableCell>{vac.matchings.length}</TableCell>
              <TableCell>
                {statusMap.has(vac.status) && statusMap.get(vac.status)}
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    setVacancy(vac)
                    setDrawerOpened(true)
                  }}
                >
                  <KeyboardArrowRight />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}
