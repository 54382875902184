import { parseISO } from 'date-fns'
import { DateTime } from 'luxon'
import { LeadDetailsDto, PipedriveDealDto, QuoteDto } from 'types/dto'
import { ContractType } from 'types/enum'
import { servicesListToAutocompleteOptions } from 'utils/enums'

export type LeadSelection = {
  label: string
  id: string
  data?: PipedriveDealDto
}

export type QuoteSelection = {
  label: string
  id: string
  data?: QuoteDto
}

export type UpdateQuoteForm = {
  missionStart: Date
  missionEnd: Date | null
  services: { label: string; value: string }[]
  contractType: ContractType
}

export const toLeadSelection = (dealDto: PipedriveDealDto) => ({
  label: `${dealDto.title} - ${dealDto.id}`,
  id: dealDto.id.toString(),
  data: dealDto
})

export const toQuoteSelection = (quoteDto: QuoteDto) => ({
  label: quoteDto.reference,
  id: quoteDto.id,
  data: quoteDto
})

export const toUpdateQuoteForm = (
  selectedQuote: QuoteSelection | undefined | null,
  leadDetails: LeadDetailsDto | undefined
): UpdateQuoteForm => ({
  missionStart: parseISO(
    selectedQuote?.data?.missionStart ||
      leadDetails?.announce?.missionStart ||
      ''
  ),
  missionEnd: selectedQuote?.data?.missionEnd
    ? parseISO(selectedQuote?.data?.missionEnd)
    : null,
  services: servicesListToAutocompleteOptions(
    selectedQuote?.data?.services || []
  ),
  contractType: (selectedQuote?.data?.contractType || 'liberal') as ContractType
})

export const getEstimateEndDate = (
  startDate: Date,
  endDate: Date | null,
  estimationType: string
) => {
  if (estimationType === 'custom') {
    return DateTime.fromJSDate(endDate as Date).toFormat('yyyy-MM-dd')
  }

  return DateTime.fromJSDate(startDate).plus({ days: 7 }).toFormat('yyyy-MM-dd')
}
