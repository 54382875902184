import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import { isValidBIC } from 'ibantools'
import { FormikProps } from 'formik'

interface BICInputProps {
  id: string
  label?: string
  form: FormikProps<any>
}

export const BICInput: React.FC<BICInputProps> = ({ id, label, form }) => {
  const [error, setError] = useState(false)

  const handleChange = (event: any) => {
    const rawValue = event.target.value.replace(/\s/g, '') // Remove existing spaces

    setError(!isValidBIC(rawValue))
    form.setFieldValue(id, rawValue)
  }

  return (
    <TextField
      id={id}
      label={label ?? 'BIC'}
      value={form.values[id]}
      onChange={handleChange}
      error={error}
      helperText={error ? 'BIC invalide' : ''}
      variant="outlined"
      fullWidth
      InputLabelProps={{
        shrink: true
      }}
    />
  )
}
