import { SwitchProps } from '@mui/material'
import React from 'react'
import {
  SwitchInput,
  SwitchRoot,
  SwitchThumb,
  SwitchTrack
} from './Switch.style'

const clsx = ({
  checked,
  disabled,
  focusVisible
}: {
  checked: boolean
  disabled: boolean
  focusVisible: boolean
}) => {
  if (checked) {
    return 'checked'
  }
  if (disabled) {
    return 'disabled'
  }
  if (focusVisible) {
    return 'focusVisible'
  }
}

export const Switch: React.FC<
  SwitchProps & { yesValue: string; noValue: string }
> = ({
  checked = false,
  disabled = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  yesValue = 'OUI',
  noValue = 'NON'
}) => {
  const stateClasses = {
    checked,
    disabled,
    focusVisible: true
  }

  const change = (event: any) => {
    onChange(event, !checked)
  }

  return (
    <SwitchRoot
      yesValue={yesValue}
      noValue={noValue}
      className={clsx(stateClasses)}
    >
      <SwitchTrack
        className={clsx(stateClasses)}
        yesValue={yesValue}
        noValue={noValue}
      >
        <SwitchThumb
          yesValue={yesValue}
          noValue={noValue}
          className={clsx(stateClasses)}
        />
      </SwitchTrack>
      <SwitchInput
        checked={checked}
        disabled={disabled}
        onClick={event => change(event)}
      />
    </SwitchRoot>
  )
}
