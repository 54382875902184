import { getApiClient } from 'adapters/backend/network'
import { Mission } from 'types/entity'

export const getHDs = async (
  missionId: string,
  date: string
): Promise<{ mission: Mission; prices: any[], allValidated: boolean }> => {
  return getApiClient()
    .get<{ mission: Mission; prices: any[], allValidated: boolean }>(
      `/hourDeclaration/get-mission/${missionId}/${date}`
    )
    .then(({ data }) => data)
}

export const postValidation = async (missionId: string, date: string,): Promise<void> => {
  await getApiClient()
    .post<void>(`/hourDeclaration/validate-all-family/${missionId}/${date}`)
    .catch(() => ({}))
}

export const postConflict = async (missionId: string): Promise<void> => {
  await getApiClient()
    .post<void>(`/hourDeclaration/conflict-all-family/${missionId}`)
    .catch(() => ({}))
}
