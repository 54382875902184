import { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../theme'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Keycloak from 'keycloak-js'
import Nav from '../Nav/Nav'
import Copyright from '../Copyright/Copyright'
import Unauthorized from '../Unauthorized/Unauthorized'
import {
  getCareGiverMatchingVacancies,
  searchCareGivers,
  setInitial,
  setWaiting
} from '../../adapters/microservices/instititions'
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Fab,
  Modal,
  TextField
} from '@mui/material'
import moment from 'moment'
import Title from '../Title/Title'
import Typography from '@mui/material/Typography'

const isVacSelectable = (status: string, matchingStatus: string): boolean =>
  (status === 'INITIAL' || status === 'NON_ASSIGNED') &&
  matchingStatus !== 'WAITING' &&
  matchingStatus !== 'SENT_ESTABLISHMENT'

const isVacWaiting = (status: string, matchingStatus: string): boolean =>
  status !== 'ASSIGNED' &&
  (matchingStatus === 'WAITING' || matchingStatus === 'SENT_ESTABLISHMENT')

export default function CareGiver({ keycloak }: { keycloak?: Keycloak }) {
  const [careGiver, setCareGiver] = useState<any>()
  const [vacancies, setVacancies] = useState<any[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [establishments, setEstablishments] = useState<any[]>([])
  const [groups, setGroups] = useState<any>({})
  const [selectedVacancies, setSelectedVacancies] = useState<any[]>([])
  const [modalOpened, setModalOpened] = useState(false)
  const [adminNumber, setAdminNumber] = useState('')
  const [loading, setLoading] = useState(true)

  const toggleModal = () => {
    setModalOpened(!modalOpened)
  }

  const handleConfirm = async (): Promise<void> => {
    for (const vac of selectedVacancies) {
      await setWaiting(vac.currentCareGiverMatching.id)
    }

    reloadMatchings()
  }

  const findCareGivers = async (phone: string): Promise<void> => {
    const foundCareGivers = await searchCareGivers(phone)

    if (foundCareGivers.length) {
      setCareGiver(foundCareGivers[0])
    }
  }

  const handleCheckbox = (vac: any) => {
    if (selectedVacancies.find(s => s.id === vac.id)) {
      const vacs = selectedVacancies.filter(({ id }) => id !== vac.id)

      setSelectedVacancies(vacs)

      return
    }

    const newSelectedVacsAdd = [...selectedVacancies, vac]

    setSelectedVacancies(newSelectedVacsAdd)
  }

  const reloadMatchings = () => {
    setLoading(true)
    if (!careGiver) {
      return
    }

    getCareGiverMatchingVacancies(careGiver.id).then(([vacs, ests, grps]) => {
      setVacancies(vacs)
      setEstablishments(ests)
      setGroups(grps)
      setSelectedVacancies([])
      setLoading(false)
    })
  }

  useEffect(() => {
    if (adminNumber.length < 8) {
      return
    }
    findCareGivers(adminNumber)
  }, [adminNumber])

  useEffect(() => {
    reloadMatchings()
    // @TODO: pas compris le problème du linter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careGiver])

  useEffect(() => {
    if (!keycloak) {
      return
    }

    if (!keycloak.authenticated) {
      window.location.assign(keycloak.createLoginUrl())

      return
    }

    keycloak
      .loadUserProfile()
      .then((profile: any) => {
        if (profile?.attributes?.phone?.length) {
          const phone = profile.attributes.phone[0]

          findCareGivers(phone)
        }
      })
      .catch(() => window.location.assign(keycloak.createLoginUrl()))

    // if (keycloak?.tokenParsed?.preferred_username) {
    //   const phone = keycloak?.tokenParsed?.preferred_username;
    //   findCareGivers(phone);
    // }
  }, [keycloak])

  return (
    (keycloak?.authenticated && keycloak.hasRealmRole('care-giver') && (
      <ThemeProvider theme={theme}>
        <Modal open={modalOpened} onClose={toggleModal}>
          <Container
            maxWidth="sm"
            sx={{ mt: 4, mb: 4 }}
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              maxHeight: '80%'
            }}
          >
            <Paper>
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4, pb: 2, pt: 2 }}>
                <Title>Postuler</Title>
                <Box
                  maxHeight={window.innerHeight / 3}
                  overflow={'scroll'}
                  fontSize={'1.5em'}
                >
                  {selectedVacancies.map(selectedVacancy => (
                    <Box
                      key={selectedVacancy.id}
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems="center"
                      justifyContent="flex-start"
                      marginTop={2}
                      gap={2}
                    >
                      <Box>
                        <Typography fontWeight={'bold'}>
                          {selectedVacancy.establishment?.holding?.name}{' '}
                          {selectedVacancy.establishment?.name}
                        </Typography>
                        <Typography>
                          Le{' '}
                          {moment(selectedVacancy.startDate).format(
                            'DD MMM YYYY'
                          )}
                          <br />
                          de {moment(selectedVacancy.startDate).format(
                            'HH:mm'
                          )}{' '}
                          à {moment(selectedVacancy.endDate).format('HH:mm')}
                        </Typography>
                      </Box>
                      <Box>
                        <Chip
                          label={`${selectedVacancy.qualification} ${
                            selectedVacancy.fees *
                            (moment(selectedVacancy.endDate).diff(
                              moment(selectedVacancy.startDate),
                              'hours'
                            ) -
                              selectedVacancy.breakHours)
                          }€`}
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems="center"
                  justifyContent="flex-end"
                  marginTop={2}
                  gap={2}
                >
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => toggleModal()}
                  >
                    ANNULER
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleConfirm()
                      toggleModal()
                    }}
                  >
                    CONFIRMER
                  </Button>
                </Box>
              </Container>
            </Paper>
          </Container>
        </Modal>
        <Box sx={{ display: 'flex' }}>
          <Nav keycloak={keycloak} />
          <Box
            component="main"
            sx={{
              backgroundColor: currentTheme =>
                currentTheme.palette.mode === 'light'
                  ? currentTheme.palette.grey[100]
                  : currentTheme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
              paddingTop: '66px'
            }}
          >
            <Container
              maxWidth="md"
              sx={{ mt: 2, mb: 4 }}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {keycloak.hasRealmRole('impersonate-care-giver') && (
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Title>Outils admin</Title>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        value={adminNumber}
                        placeholder="Téléphone intervenant"
                        onChange={event => setAdminNumber(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p>
                        {' '}
                        {careGiver?.firstName}
                        <br />
                        {careGiver?.lastName}
                      </p>
                    </Grid>
                  </Grid>
                </Paper>
              )}
              <Typography variant="h6" marginTop={0} color={'primary'}>
                Vacations correspondant à votre profil
              </Typography>
              <br />
              <Grid container spacing={3}>
                {Object.values(groups).map((group: any) => (
                  <Grid item xs={12} key={group.key}>
                    <Paper
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="baseline"
                        justifyContent="space-between"
                      >
                        <Box flex={2} marginBottom={1}>
                          <Typography fontWeight="bold" fontSize={'16px'}>
                            {group.establishment?.holding?.name}{' '}
                            {group.establishment.name}
                          </Typography>
                          <Typography fontSize={'16px'}>
                            {group.establishment.address.city}
                          </Typography>
                          <Typography fontSize={'14px'}>
                            {group.establishment.address.street}
                          </Typography>
                        </Box>
                        <Box flex={1} marginBottom={1} textAlign={'right'}>
                          <Chip
                            label={`${group.qualification} ${
                              group.vacancies[0].fees *
                              (moment(group.vacancies[0].endDate).diff(
                                moment(group.vacancies[0].startDate),
                                'hours'
                              ) -
                                group.vacancies[0].breakHours)
                            }€`}
                          />
                        </Box>
                      </Box>
                      <Divider />
                      <br />
                      <Grid
                        container
                        direction="column"
                        spacing={4}
                        justifyContent={'space-between'}
                        wrap="nowrap"
                      >
                        {group.vacancies
                          .sort(
                            (a: any, b: any) =>
                              moment(a.startDate).unix() -
                              moment(b.startDate).unix()
                          )
                          .map((vac: any) => (
                            <Grid
                              item
                              container
                              xs={12}
                              key={vac.id}
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box
                                flex={1}
                                textAlign={'left'}
                                color={theme.extend.palette.grey.main}
                              >
                                <Typography fontWeight="bold">
                                  {moment(vac.startDate).format('DD MMM YYYY')}
                                </Typography>
                                <Typography>
                                  {moment(vac.startDate).format('HH:mm')} -{' '}
                                  {moment(vac.endDate).format('HH:mm')}
                                </Typography>
                              </Box>
                              {/* <Box flex={1} textAlign={"center"}>
                                <Chip
                                  label={`${group.qualification} ${
                                    vac.fees *
                                    (moment(vac.endDate).diff(
                                      moment(vac.startDate),
                                      "hours"
                                    ) -
                                      vac.breakHours)
                                  }€`}
                                />
                              </Box> */}
                              <Box flex={1} textAlign={'left'}>
                                {isVacWaiting(
                                  vac.status,
                                  vac.currentCareGiverMatching.status
                                ) && (
                                  <Box fontSize={'1em'}>
                                    Attente confirmation
                                  </Box>
                                )}
                                {vac.status === 'ASSIGNED' && (
                                  <Typography
                                    color={
                                      careGiver.id === vac.careGiverId
                                        ? theme.palette.success.main
                                        : theme.extend.palette.grey.light
                                    }
                                    fontWeight={
                                      careGiver.id === vac.careGiverId
                                        ? 'bold'
                                        : 'unset'
                                    }
                                  >
                                    {careGiver.id === vac.careGiverId
                                      ? 'Confirmé'
                                      : 'Indisponible'}
                                  </Typography>
                                )}
                              </Box>
                              <Box flex={0.6} textAlign={'center'}>
                                {isVacSelectable(
                                  vac.status,
                                  vac.currentCareGiverMatching.status
                                ) && (
                                  <Checkbox
                                    checked={
                                      !!selectedVacancies.find(
                                        s => s.id === vac.id
                                      )
                                    }
                                    onChange={() => handleCheckbox(vac)}
                                  />
                                )}
                                {isVacWaiting(
                                  vac.status,
                                  vac.currentCareGiverMatching.status
                                ) && (
                                  <Box>
                                    {/* <IconButton
                                      onClick={() =>
                                        setInitial(
                                          vac.currentCareGiverMatching.id
                                        ).then(() => reloadMatchings())
                                      }
                                    >
                                      <HighlightOff
                                        htmlColor={theme.palette.error.main}
                                      />
                                    </IconButton> */}
                                    <Button
                                      onClick={() =>
                                        setInitial(
                                          vac.currentCareGiverMatching.id
                                        ).then(() => reloadMatchings())
                                      }
                                      variant="text"
                                      color={'error'}
                                    >
                                      Annuler
                                    </Button>
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
                {(!vacancies.length && (
                  <Grid container margin={2} justifyContent="center">
                    <Grid item>
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Paper>
                          <Container>
                            Aucune vacation disponible pour le moment
                          </Container>
                        </Paper>
                      )}
                    </Grid>
                  </Grid>
                )) || <></>}
                {selectedVacancies.length !== 0 && (
                  <Fab
                    style={{
                      position: 'fixed',
                      bottom: theme.spacing(2),
                      right: theme.spacing(2)
                    }}
                    disabled={selectedVacancies.length === 0}
                    color="primary"
                    variant="extended"
                    onClick={() => toggleModal()}
                  >
                    POSTULER
                  </Fab>
                )}
              </Grid>
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    )) || <Unauthorized />
  )
}
