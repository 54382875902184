import { EventDropArg } from '@fullcalendar/common'
import { EventResizeDoneArg } from '@fullcalendar/interaction'
import { DateTime } from 'luxon'
import { ScheduleForm } from 'types/entity'
import { DayNb, Day, DaysList } from 'types/enum'
import { DayFromNumberToString, formatDateInHourUTC } from 'utils/tools'

const findNextDay = (day: string): Day => {
  const dayNb = Number(DayNb[day as Day])

  const nextDayIndex = dayNb === 6 ? 0 : dayNb + 1

  return DaysList[nextDayIndex]
}

export const makeSchedules = (scheduleData: ScheduleForm): ScheduleForm[] => {
  const { days, startHour, endHour, contractType, isMultiDay } = scheduleData

  if (!scheduleData.days?.length) {
    return [
      {
        ...scheduleData,
        days: undefined,
        isMultiDay: undefined
      }
    ]
  }

  if (!days || !startHour || !endHour || !contractType) {
    return []
  }

  const startTime = Number(startHour.split(':')[0] + startHour.split(':')[1])
  const endTime = Number(endHour.split(':')[0] + endHour.split(':')[1])

  // Same day schedules
  if (endTime > startTime && !isMultiDay) {
    return days.map(day => ({
      startHour,
      endHour,
      day,
      endDay: day,
      contractType
    }))
  }

  // Same day schedules but the user dragged and dropped on another day
  if (endTime > startTime && isMultiDay) {
    return days.map(day => ({
      startHour,
      endHour,
      day,
      endDay: findNextDay(day),
      contractType
    }))
  }

  // Night schedules
  if (startTime > endTime) {
    return days.map(day => ({
      startHour,
      endHour,
      day,
      endDay: findNextDay(day),
      contractType
    }))
  }

  // 24h schedules
  if (startTime === endTime) {
    return days.map(day => ({
      startHour,
      endHour,
      day,
      endDay: findNextDay(day),
      contractType
    }))
  }

  return []
}

export const getPlanningTitle = (
  isGenericWeek: boolean | undefined,
  calendarStartDate: Date
) => {
  if (isGenericWeek) {
    return 'Planning de la semaine'
  }

  const luxonDate = DateTime.fromJSDate(calendarStartDate)
  const endDate = DateTime.fromJSDate(calendarStartDate).plus({ days: 6 })

  if (luxonDate.month === endDate.month) {
    return `${luxonDate.monthShort} ${luxonDate.year}`
  }

  return `${luxonDate.monthShort} - ${endDate.monthShort} ${luxonDate.year}`
}

export const fromEventToScheduleDto = (
  arg: EventDropArg | EventResizeDoneArg
) => {
  const {
    event: {
      startStr,
      endStr,
      extendedProps: { contractType }
    }
  } = arg
  const startHour = formatDateInHourUTC(startStr)
  const endHour = formatDateInHourUTC(endStr)

  const dayNumber = new Date(startStr).getUTCDay()
  const endDayNumber = new Date(endStr).getUTCDay()
  const day =
    DayFromNumberToString[dayNumber as keyof typeof DayFromNumberToString]
  const endDay =
    DayFromNumberToString[endDayNumber as keyof typeof DayFromNumberToString]

  return {
    startHour,
    endHour,
    day,
    endDay,
    contractType
  }
}
