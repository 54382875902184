import { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Title from '../Title/Title'
import {
  Checkbox,
  Chip,
  Container,
  Drawer,
  IconButton,
  Tooltip
} from '@mui/material'
import { GradientButton } from '../Button/Button.style'
import { Switch } from '../Switch'
import {
  getVacancyMatchings,
  setRequestSend
} from '../../adapters/microservices/instititions'
import { Pending, Replay, Textsms } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import Keycloak from 'keycloak-js'

type MatchingsProps = {
  vacancy: any
  drawerOpened: boolean
  setDrawerOpened(val: boolean): void
  keycloak: Keycloak
}

const areArraysEqual = (a1: any[], a2: any[]) =>
  a1.length === a2.length &&
  a1.filter(valA1 => a2.indexOf(valA1) !== -1).length === a2.length

export default function Matchings({
  vacancy,
  drawerOpened,
  setDrawerOpened,
  keycloak
}: MatchingsProps) {
  const [matchings, setMatchings] = useState<any[]>([])
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const theme = useTheme()

  const statusMap = new Map([
    [
      'INITIAL',
      <Tooltip title="SMS Non envoyé">
        <Textsms htmlColor={theme.extend.palette.grey.main}></Textsms>
      </Tooltip>
    ],
    [
      'REQUEST_SEND_LV1',
      <Tooltip title="SMS En cours d'envoi">
        <Textsms htmlColor={theme.palette.warning.main}></Textsms>
      </Tooltip>
    ],
    [
      'SENT_LV1',
      <Tooltip title="SMS Envoyé">
        <Textsms htmlColor={theme.palette.success.main}></Textsms>
      </Tooltip>
    ],
    [
      'NOT_SENT',
      <Tooltip title="SMS Non envoyé">
        <Textsms htmlColor={theme.palette.error.main}></Textsms>
      </Tooltip>
    ],
    [
      'WAITING',
      <Tooltip title="Liste d'attente">
        <Pending htmlColor={theme.extend.palette.grey.main}></Pending>
      </Tooltip>
    ],
    [
      'SENT_ESTABLISHMENT',
      <Tooltip title="Envoyé à l'établissement">
        <Pending htmlColor={theme.palette.success.main}></Pending>
      </Tooltip>
    ]
  ])

  useEffect(() => {
    setSelectedIds([])
  }, [matchings])

  const fetchMatchings = () => {
    if (!vacancy?.id) {
      return
    }
    getVacancyMatchings(
      new Map<string, any>(),
      undefined,
      undefined,
      vacancy.id
    ).then(([vacancyMatchings]) => setMatchings(vacancyMatchings))
  }

  useEffect(() => {
    if (!drawerOpened) {
      return
    }
    fetchMatchings()
  }, [vacancy, drawerOpened])

  const handleSendSms = async () => {
    await Promise.all(selectedIds.map(id => setRequestSend(id)))
    setTimeout(() => {
      fetchMatchings()
    }, 600)
  }

  const handleAllCheckbox = () => {
    if (
      selectedIds.length ===
      matchings.filter(({ status }) => status !== 'WAITING').length
    ) {
      setSelectedIds([])

      return
    }

    const newSelectedIds = matchings
      .filter(({ status }) => status !== 'WAITING')
      .map(({ id }) => id)

    setSelectedIds(newSelectedIds)
  }

  const handleTop10 = () => {
    if (
      areArraysEqual(
        selectedIds,
        matchings
          .filter(({ status }) => status !== 'WAITING')
          .map(({ id }) => id)
          .slice(0, 10)
      )
    ) {
      setSelectedIds([])

      return
    }

    setSelectedIds(
      matchings
        .filter(({ status }) => status !== 'WAITING')
        .map(({ id }) => id)
        .slice(0, 10)
    )
  }

  const handleCheckbox = (matchId: string) => {
    if (selectedIds.indexOf(matchId) !== -1) {
      const newSelectedIds = selectedIds.filter(id => id !== matchId)

      setSelectedIds(newSelectedIds)

      return
    }

    const newSelectedIdsAdd = [...selectedIds, matchId]

    setSelectedIds(newSelectedIdsAdd)
  }

  return (
    <Drawer
      style={{ zIndex: 3 }}
      anchor="right"
      open={drawerOpened}
      onClose={() => {
        setDrawerOpened(false)
        setMatchings([])
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
        <Title>
          Intervenants trouvés pour ce besoin{' '}
          <IconButton onClick={() => fetchMatchings()}>
            <Replay></Replay>
          </IconButton>
          {keycloak?.authenticated &&
            keycloak?.hasRealmRole('send-vacancies-notification') && (
              <GradientButton
                style={{ float: 'right' }}
                disabled={selectedIds.length === 0}
                onClick={() => handleSendSms()}
              >
                Envoyer {selectedIds.length} SMS
              </GradientButton>
            )}
        </Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Score</TableCell>
              <TableCell>Niveau d'onboarding</TableCell>
              <TableCell>Intervenant</TableCell>
              <TableCell>Profil</TableCell>
              <TableCell>Téléphone</TableCell>
              <TableCell>Statut</TableCell>
              <TableCell>Commentaire</TableCell>
              <TableCell>
                <Switch
                  onChange={() => handleAllCheckbox()}
                  checked={selectedIds.length === matchings.length}
                  yesValue="TOUS"
                  noValue="TOUS"
                />
                <br />
                <Switch
                  onChange={() => handleTop10()}
                  checked={areArraysEqual(
                    selectedIds,
                    matchings.map(({ id }) => id).slice(0, 10)
                  )}
                  yesValue="TOP       10"
                  noValue="TOP       10"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matchings.map(match => (
              <TableRow key={match.id}>
                <TableCell>
                  <span
                    style={{
                      fontWeight: 'bold',
                      color:
                        match.score > 0
                          ? theme.palette.success.main
                          : match.score < 0
                          ? theme.palette.error.main
                          : theme.extend.palette.grey.main
                    }}
                  >
                    {match.score}
                  </span>
                </TableCell>
                <TableCell>{match?.careGiver?.onboardingStatus}</TableCell>
                <TableCell>
                  {match.careGiver.firstName} {match.careGiver.lastName}
                </TableCell>
                <TableCell>
                  <Chip label={match.careGiver.qualification} />
                </TableCell>
                <TableCell>{match.careGiver.phone}</TableCell>
                <TableCell>
                  {statusMap.has(match.status) && statusMap.get(match.status)}
                </TableCell>
                <TableCell>{match.careGiver.comment}</TableCell>
                <TableCell>
                  <Checkbox
                    onChange={() => handleCheckbox(match.id)}
                    checked={selectedIds.indexOf(match.id) !== -1}
                    disabled={match.status === 'WAITING'}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </Drawer>
  )
}
