import { getPrestationCustomer, savePrestationCustomer } from 'adapters/backend'
import { codeTypeVoieMap } from 'adapters/data.gouv/Fantoir.enum'
import { PrestationCustomerDto } from 'types/dto'

export const createOrUpdatePrestationCustomer = async (
  externalId: string,
  formData: any,
  update: boolean
) => {
  const customer: PrestationCustomerDto = {
    externalId,
    civilite: formData.gender,
    adresseMail: formData.email,
    numeroTelephonePortable: formData.mobilePhone,
    nomNaissance: formData.birthName,
    nomUsage: formData.lastName,
    prenoms: formData.firstNames,
    numeroVoie: formData.fantoirNumber,
    lettreVoie: formData.fantoirLetter,
    fantoirId: formData.fantoirStreet.id,
    codeTypeVoie: formData.fantoirStreet.codeNatureVoie,
    libelleVoie: formData.fantoirStreet.libelleVoie,
    complement: '',
    lieuDit: '',
    codePostal: formData.fantoirPostal,
    codeCommune: formData.fantoirCity.citycode,
    libelleCommune: formData.fantoirCity.label,
    codePays: '99100', // France par défaut
    dateNaissance: formData.birthDate,
    codePaysNaissance: formData.birthCountry,
    codeCommuneNaissance: formData.birthCity.citycode.substring(2, 5),
    libelleCommuneNaissance: formData.birthCity.label,
    departementNaissance:
      formData.birthCity.citycode !== ''
        ? '0' + formData.birthCity.citycode.substring(0, 2)
        : '',
    iban: formData.iban,
    bic: formData.bic,
    titulaire: formData.accountHolder,
    enabled: formData.enabled
  }

  await savePrestationCustomer(customer, update)
}

export const hydratePrestationCustomerFormData = async (externalId: string) => {
  try {
    const customer = await getPrestationCustomer(externalId)

    const birthCityCode = customer.departementNaissance
      ? `${Number(customer.departementNaissance).toString()}${
          customer.codeCommuneNaissance
        }`
      : ''

    const formData = {
      gender: customer.civilite ?? 1,
      firstNames: customer.prenoms ?? '',
      birthDate: new Date(customer.dateNaissance ?? ''),
      birthName: customer.nomNaissance ?? '',
      lastName: customer.nomUsage ?? '',
      birthCity: {
        label: customer.libelleCommuneNaissance ?? '',
        id: birthCityCode,
        citycode: birthCityCode
      },
      birthCountry: customer.codePaysNaissance ?? '',
      mobilePhone: customer.numeroTelephonePortable ?? '',
      email: customer.adresseMail ?? '',
      fantoirCity: {
        label: customer.libelleCommune ?? '',
        id: customer.codeCommune ?? '',
        postcode: customer.codePostal ?? '',
        citycode: customer.codeCommune ?? ''
      },
      fantoirNumber: customer.numeroVoie ?? '',
      fantoirLetter: customer.lettreVoie ?? '',
      fantoirStreet: {
        id: customer.fantoirId ?? '',
        libelleVoie: customer.libelleVoie ?? '',
        codeTypeVoie: customer.codeTypeVoie ?? '',
        natureVoie: customer.codeTypeVoie
          ? codeTypeVoieMap.get(customer.codeTypeVoie) ?? ''
          : ''
      },
      fantoirPostal: customer.codePostal ?? '',
      accountHolder: customer.titulaire ?? '',
      iban: customer.iban ?? '',
      bic: customer.bic ?? '',
      apiStatus: customer.apiStatus ?? '',
      apiIdClient: customer.apiIdClient ?? '',
      enabled: customer.enabled ?? true
    }

    return formData
  } catch (error) {
    return undefined
  }
}

export const prestationCustomerInitialValues = {
  gender: 1,
  firstNames: '',
  birthDate: new Date('1935-06-06'),
  birthName: '',
  lastName: '',
  birthCity: {
    label: '',
    id: '',
    citycode: ''
  },
  birthCountry: '',
  mobilePhone: '',
  email: '',
  fantoirCity: {
    label: '',
    id: '',
    citycode: ''
  },
  fantoirNumber: '',
  fantoirLetter: '',
  fantoirStreet: {
    id: '',
    codeTypeVoie: '',
    libelleVoie: '',
    natureVoie: ''
  },
  fantoirPostal: '',
  accountHolder: '',
  iban: '',
  bic: '',
  apiIdClient: '',
  apiStatus: '',
  enabled: true
}

// type Nullable<T> = {
//   [P in keyof T]?: T[P] | null
// }

// type PrestationCustomerFormData = {
//   gender: number | null
//   firstNames: string | null
//   birthDate: Date | null
//   birthName: string | null
//   lastName: string | null
//   birthCity: Nullable<DataGouvMunicipality>
//   birthCountry: string | null
//   mobilePhone: string | null
//   email: string | null
//   fantoirCity: Nullable<DataGouvMunicipality>
//   fantoirNumber: string | null
//   fantoirLetter: string | null
//   fantoirStreet: Nullable<FantoirStreet>
//   fantoirPostal: string | null
//   accountHolder: string | null
//   iban: string | null
//   bic: string | null
// }
