import { useEffect, useState } from 'react'
import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  Button,
  Snackbar,
  Alert,
  AlertTitle
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ThemeProvider, useTheme } from '@emotion/react'
import { Phone } from '@mui/icons-material'
import { searchAddress } from 'adapters/data.gouv/addressApi'
import { postFamilyLead } from 'utils/backendApi'
import AddressAutocomplete from 'components/Contact/AddressAutocomplete'
import BeneficiaryFields from 'components/Contact/BeneficiaryFields'
import BeneficiaryLink from 'components/Contact/BeneficiaryLink'
import ReferralContact from 'components/Contact/ReferralContact'

const validationSchema = yup.object({
  referralEmail: yup
    .string()
    .email('Merci de renseigner un email valide')
    .required("L'email référent est requis"),
  referralPhone: yup.string().required('Le téléphone du référent est requis'),
  referralLink: yup.string().required('Lien avec le bénéficiaire requis'),
  address: yup.string().required("L'adresse est requise"),
  source: yup.string().required('La source est requise'),
  firstNameBenef: yup.string().required('Le prénom du bénéficiaire est requis'),
  lastNameBenef: yup
    .string()
    .required('Le nom de famille du bénéficiaire est requis')
})

export default function Contact() {
  const searchParams = new URLSearchParams(window.location.search)
  const [addresses, setAddresses] = useState([])
  const [selectedAddress, setSelectedAddress] = useState<any>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  const theme = useTheme()
  const [urlEmail, setUrlEmail] = useState('')
  const [openedSuccessSnackbar, setOpenedSuccessSnackbar] = useState(false)
  const [openedFailureSnackbar, setOpenedFailureSnackbar] = useState(false)

  useEffect(() => {
    if (!searchParams.has('email')) {
      return
    }

    yup
      .string()
      .email()
      .validate(searchParams.get('email'))
      .then(() => {
        setUrlEmail(searchParams.get('email') || '')
        formik.setFieldValue('referralEmail', searchParams.get('email') || '')
      })
      .catch()
  }, [])

  const formik = useFormik({
    initialValues: {
      referralEmail: urlEmail,
      referralPhone: '',
      referralLink: '',
      address: '',
      source: 'Contact',
      firstNameBenef: '',
      lastNameBenef: ''
    },
    validationSchema,
    onSubmit: values => {
      setIsSubmitting(true)
      const body = {
        referral: {
          email: values.referralEmail,
          phone: values.referralPhone,
          relationType: values.referralLink
        },
        beneficiary: {
          firstName: values.firstNameBenef,
          lastName: values.lastNameBenef,
          address: {
            gpsLng: selectedAddress.geometry.coordinates[0],
            gpsLat: selectedAddress.geometry.coordinates[1],
            city: selectedAddress.properties.city,
            postCode: selectedAddress.properties.postcode,
            address: selectedAddress.properties.name
          }
        }
      }

      postFamilyLead(body)
        .then(() => {
          setIsSubmitting(false)
          // alert('Votre demande de contact a bien été envoyée')
          setOpenedSuccessSnackbar(true)
          formik.resetForm()
          setAddresses([])
        })
        .catch(() => {
          setIsSubmitting(false)
          setOpenedFailureSnackbar(true)
          // alert('Une erreur est survenue')
        })
    }
  })

  useEffect(() => {
    if (!formik.values.address || formik.values.address.length <= 3) {
      return
    }

    searchAddress(formik.values.address).then((addresses): void => {
      setAddresses(addresses)
    })
  }, [formik.values.address])

  const filterOptions = (options: any[]) => options

  return (
    <ThemeProvider theme={theme}>
      <Container style={{ padding: '20px' }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openedSuccessSnackbar}
          onClose={() => setOpenedSuccessSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenedSuccessSnackbar(false)}
            severity="success"
            variant="filled"
            sx={{ width: '100%', color: 'white' }}
          >
            <AlertTitle>Nous vous remercions de votre confiance !</AlertTitle>
            Notre conseiller dédié prend connaissance de votre besoin et vous
            fait un retour dans les plus brefs délais.
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openedFailureSnackbar}
          onClose={() => setOpenedFailureSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenedFailureSnackbar(false)}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            <AlertTitle>Erreur</AlertTitle>
            Une erreur est survenue
          </Alert>
        </Snackbar>
        <Grid container>
          <Paper style={{ padding: '20px', width: '100%' }}>
            <Grid container direction={'column'} alignItems={'center'}>
              <img
                src="./img/logo-click-and-care-color.png"
                style={{
                  height: isSm ? '50px' : '150px',
                  marginBottom: '20px'
                }}
              />
              <Typography
                component="h2"
                variant={isSm ? 'h5' : 'h3'}
                color="primary"
                fontWeight={'bold'}
                align="center"
                display={'inline-block'}
                paddingLeft={'10px'}
                paddingRight={'10px'}
                marginBottom={'25px'}
              >
                Comment pouvons-nous vous aider ?
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={0} direction={'column'}>
                  <BeneficiaryLink formik={formik} />
                  <BeneficiaryFields formik={formik} />
                  <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    <AddressAutocomplete
                      addresses={addresses}
                      setSelectedAddress={setSelectedAddress}
                      filterOptions={filterOptions}
                      formik={formik}
                    />
                  </Grid>
                  <ReferralContact
                    formik={formik}
                    urlEmail={urlEmail}
                    institution={false}
                  />
                  <Grid item xs={12}>
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth
                      type="submit"
                      size={'large'}
                      disabled={isSubmitting}
                      startIcon={<Phone />}
                    >
                      {isSubmitting ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        "Être rappelé aujourd'hui"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}
