import { searchAddress } from 'adapters/data.gouv/addressApi'
import { Autocomplete, TextField } from '@mui/material'

export default function AddressAutocomplete({
  addresses,
  setSelectedAddress,
  filterOptions,
  formik
}: any) {
  return (
    <Autocomplete
      freeSolo
      fullWidth
      id="search-address"
      options={addresses.map((address: any) => address.properties.label)}
      onChange={(event, value) =>
        setSelectedAddress(
          addresses.find(
            (address: any) => address.properties.label === value
          ) || {}
        )
      }
      filterOptions={filterOptions}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          id="address"
          name="address"
          label={
            formik.values.referralLink === 'beneficiaire'
              ? 'Adresse'
              : formik.values.referralLink === 'other'
              ? 'CP, Ville du bénéficiaire'
              : '📍 Adresse du bénéficiaire'
          }
          type="text"
          value={searchAddress}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
        />
      )}
    />
  )
}
