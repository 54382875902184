/* eslint-disable react/prop-types */
import { Autocomplete, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { searchCity } from 'adapters/data.gouv/addressApi'
import { DataGouvMunicipality } from 'adapters/data.gouv/type'
import { FormikProps } from 'formik'
import { useState } from 'react'

interface CityAutocompleteProps {
  id: string
  label?: string
  disabled?: boolean
  form: FormikProps<any>
}

export const CityAutocomplete: React.FC<CityAutocompleteProps> = ({
  id,
  label,
  disabled,
  form
}) => {
  const [inputValue, setInputValue] = useState('')

  const { data: cities } = useQuery({
    queryKey: ['cities', inputValue],
    enabled: inputValue.length > 2,
    queryFn: () => searchCity(inputValue)
  })

  return (
    <Autocomplete
      id={id}
      noOptionsText="Aucune ville trouvée"
      disabled={disabled}
      options={cities ?? []}
      filterOptions={x => x} // always show all options
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionKey={(option: DataGouvMunicipality) => option.id}
      getOptionLabel={(option: DataGouvMunicipality) =>
        option.citycode !== ''
          ? `${option.label} (${option.citycode.substring(0, 2)})`
          : ''
      }
      value={form.values[id]}
      renderInput={params => (
        <TextField
          {...params}
          type="text"
          label={label ?? 'ville'}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.toString().length > 2) {
          setInputValue(newInputValue.toString())
        }
      }}
      onChange={(_, value) => {
        form.setFieldValue(id, value)
      }}
      onBlur={form.handleBlur}
    />
  )
}
