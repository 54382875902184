import { PrestationCustomerDto } from 'types/dto'
import { getApiClient } from './network'

export const savePrestationCustomer = async (
  customer: PrestationCustomerDto,
  update = false
): Promise<PrestationCustomerDto> => {
  if (update) {
    return updatePrestationCustomer(customer)
  }

  return createPrestationCustomer(customer)
}

export const createPrestationCustomer = async (
  customer: PrestationCustomerDto
): Promise<PrestationCustomerDto> => {
  const { data } = await getApiClient().post<PrestationCustomerDto>(
    `/urssaf/customer`,
    {
      ...customer
    }
  )

  return data
}

export const updatePrestationCustomer = async (
  customer: PrestationCustomerDto
): Promise<PrestationCustomerDto> => {
  const { data } = await getApiClient().put<PrestationCustomerDto>(
    `/urssaf/customer/${customer.externalId}`,
    {
      ...customer
    }
  )

  return data
}

export const registerPrestationCustomer = async (externalId: string) => {
  const { data } = await getApiClient().post(
    `/urssaf/customer/${externalId}/registration`,
    {}
  )

  return data
}

export const getPrestationCustomer = async (
  externalId: string
): Promise<PrestationCustomerDto> => {
  const { data } = await getApiClient().get<PrestationCustomerDto>(
    `/urssaf/customer/${externalId}`
  )

  return data
}
