import {
  ContractType,
  Day,
  FAMILY_SERVICES,
  QUOTE_STATUS,
  STATUS
} from './enum'

export interface Schedule {
  id: string
  day: Day
  endDay?: Day
  startHour: string
  endHour: string
  announce?: Announce
  mission?: Mission
  substitution?: Substitution
  contractType: ContractType
  type: string
}

export type RealScheduleEstimate = Schedule & {
  prices: Tarif[]
  startDate: Date
  endDate: Date
}

export interface Substitution {
  startDate: Date
  endDate?: Date
  initialMission: Mission
}

export interface Announce {
  id: string
  startDate: Date
  endDate?: Date
  contractType: ContractType
  status: STATUS
  schedule?: Schedule[]
  reference: string
  beneficiary?: Beneficiary
}

export interface Mission {
  id: string
  startDate: Date
  endDate?: Date
  hourSalary: number
  announce: Announce
  careGiver: CareGiver
  contractType: ContractType
}

export interface Quote {
  id: string
  reference: string
  pipedriveId: string
  s3Url?: string
  missionStart: Date
  missionEnd?: Date
  status: QUOTE_STATUS
  backofficeAdmin?: string
  date?: Date | undefined
  validityDate?: Date | undefined
  leadSnapshot?: string
  services?: FAMILY_SERVICES[]
  schedules?: Schedule[]
  contractType?: ContractType
  estimationPeriod?: string
}

export interface Person {
  firstName: string | null
  lastName: string | null
  phone: string
  numberSocialSecurity: string
}

export interface CareGiver {
  id: string
  distanceMaxCriteria: number | null
  startDateCriteria: Date | null
  siret: string
  nanCesu: string
  sapNova: string
  hasCar: boolean | null
  person: Person
}

export interface ScheduleForm {
  id?: string
  startHour?: string
  endHour?: string
  day?: string
  endDay?: string
  contractType?: string
  days?: string[]
  isMultiDay?: boolean
}

export interface Beneficiary {
  familyAnnounces?: Announce[]
  zone: string | null
}

export interface Tarif {
  candCTaxExcludedAmount: number
  candCVATAmount: number
  candCVATRate: number
  careGiverTaxExcludedAmount: number
  careGiverVATAmount: number
  careGiverVATRate: number
  product: string
  surchargeType: string
  travelFees: number
  hoursBilled: number
  globalFees: number
}

export interface TotalTarif {
  candCTaxExcludedAmount: number
  candCVATAmount: number
  careGiverTaxExcludedAmount: number
  careGiverVATAmount: number
  travelFees: number
  hoursBilled: number
  totalTaxIncluded: number
  globalFees: number
}

export interface Lead {
  announce: Announce
}

export interface Application {
  announce: Announce
}

const ENTITY_TYPES = [
  'lead',
  'beneficiary',
  'announce',
  'application',
  'mission',
  'quote'
] as const

// https://stackoverflow.com/questions/51528780/typescript-check-typeof-against-custom-type
export type EntityType = (typeof ENTITY_TYPES)[number]
export const isEntityType = (x: any): x is EntityType =>
  ENTITY_TYPES.includes(x)

export interface CalendarContainerProps {
  entity: EntityType
  id: string
}
