import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import AddCardIcon from '@mui/icons-material/AddCard'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PeopleIcon from '@mui/icons-material/People'
import { Home, LocalHospital, PowerSettingsNew } from '@mui/icons-material'
import ReceiptIcon from '@mui/icons-material/Receipt'
import Keycloak from 'keycloak-js'
import { getIsMobile } from '../../hooks/responsive.hook'

const drawerWidth = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
  isMobile: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open' && prop !== 'isMobile'
})<AppBarProps>(({ theme, open, isMobile }) => ({
  zIndex: 2,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open &&
    !isMobile && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    })
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 240,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(8),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7)
      }
    }),
    zIndex: 1
  }
}))

const DrawerContent = ({
  navigate,
  toggleDrawer,
  keycloak
}: {
  navigate: (url: string) => void
  toggleDrawer: () => void
  keycloak?: Keycloak
}) => (
  <>
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1]
      }}
    >
      <IconButton onClick={toggleDrawer}>
        <ChevronLeftIcon />
      </IconButton>
    </Toolbar>
    <Divider />
    <List component="nav">
      <React.Fragment>
        <ListItemButton onClick={() => navigate('/')}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Accueil" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/devis')}>
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary="Devis" />
        </ListItemButton>
        {keycloak?.authenticated && keycloak.hasRealmRole('show-vacancies') && (
          <ListItemButton onClick={() => navigate('/staffing')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Staffing Automatique" />
          </ListItemButton>
        )}
        {keycloak?.authenticated && keycloak.hasRealmRole('edit-urssaf') && (
          <ListItemButton onClick={() => navigate('/urssaf')}>
            <ListItemIcon>
              <AddCardIcon />
            </ListItemIcon>
            <ListItemText primary="Crédits d'impots" />
          </ListItemButton>
        )}
        {keycloak?.authenticated && keycloak.hasRealmRole('care-giver') && (
          <ListItemButton onClick={() => navigate('/intervenant')}>
            <ListItemIcon>
              <LocalHospital />
            </ListItemIcon>
            <ListItemText primary="Espace intervenant" />
          </ListItemButton>
        )}
        {keycloak?.authenticated && (
          <ListItemButton
            onClick={() =>
              keycloak && window.location.assign(keycloak?.createLogoutUrl())
            }
          >
            <ListItemIcon>
              <PowerSettingsNew />
            </ListItemIcon>
            <ListItemText primary="Déconnexion" />
          </ListItemButton>
        )}
      </React.Fragment>
    </List>
  </>
)

export default function Nav({ keycloak }: { keycloak?: Keycloak }) {
  const [open, setOpen] = React.useState(false)

  const isMobile = getIsMobile()

  const navigate = useNavigate()

  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <>
      <CssBaseline />
      <AppBar position="absolute" open={open} isMobile={isMobile}>
        <Toolbar
          sx={{
            pr: '24px' // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={
              {
                // marginRight: "36px",
                // ...(open && { display: "none" }),
              }
            }
          >
            <MenuIcon />
          </IconButton>
          <img
            src="/img/Logo.png"
            style={{
              display: 'inline-block',
              height: '50px'
            }}
            alt="logo"
          />
        </Toolbar>
      </AppBar>
      {isMobile && (
        <MuiDrawer variant="temporary" open={open} style={{ zIndex: 2 }}>
          <DrawerContent
            keycloak={keycloak}
            navigate={navigate}
            toggleDrawer={toggleDrawer}
          />
        </MuiDrawer>
      )}
      {!isMobile && (
        <Drawer variant="permanent" open={open}>
          <DrawerContent
            keycloak={keycloak}
            navigate={navigate}
            toggleDrawer={toggleDrawer}
          />
        </Drawer>
      )}
    </>
  )
}
