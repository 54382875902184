import React, { ReactNode, createContext, useContext, useState } from 'react'

interface ScheduleContextValue {
  scheduleData: any
  updateScheduleData: (data: any) => void
}

const ScheduleContext = createContext<ScheduleContextValue | undefined>(undefined)

export const useScheduleContext = () => {
  const context = useContext(ScheduleContext)

  if (!context) {
    throw new Error('useScheduleContext must be used within a ScheduleProvider')
  }

  return context
}

export const ScheduleProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [scheduleData, setScheduleData] = useState<any>(null)

  const updateScheduleData = (data: any) => {
    setScheduleData(data)
  }

  const value: ScheduleContextValue = {
    scheduleData,
    updateScheduleData
  }

  return (
    <ScheduleContext.Provider value={value}>{children}</ScheduleContext.Provider>
  )
}
