import { Grid, Stack } from '@mui/material'
import { getTotalsFromRealScheduleEstimates } from 'utils/schedules'

export const QuotePricing = ({
  pricingData,
  multiplier
}: {
  pricingData: any
  multiplier: number
}) => {
  return (
    <Grid item md={12}>
      <Stack>
        {pricingData && (
          <Stack
            flexDirection={'row'}
            justifyContent={'space-between'}
            width="100%"
          >
            <div>
              Total:{' '}
              {(
                Number(
                  getTotalsFromRealScheduleEstimates(pricingData).globalFees /
                    100
                ) * multiplier
              ).toFixed(2) + ' €'}
            </div>
            <div>
              {Number(
                getTotalsFromRealScheduleEstimates(pricingData).hoursBilled *
                  multiplier
              ).toFixed(2) + ' heure(s) facturée(s)'}
            </div>
          </Stack>
        )}
      </Stack>
    </Grid>
  )
}
