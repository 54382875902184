import { getApiClient } from 'adapters/backend/network'
import { Announce, Mission, Schedule } from 'types/entity'
import { CreateScheduleDto } from 'types/dto'

export const getAllSchedulesByAnnounceId = async (
  id: string,
  apiKey?: string
): Promise<Schedule[]> => {
  const { data: schedules } = await getApiClient(apiKey).get<Schedule[]>(
    `/announces/${id}/schedules`
  )

  return schedules
}

export const getAllSchedulesByMissionId = async (
  id: string,
  apiKey?: string
): Promise<Schedule[]> => {
  const { data: schedules } = await getApiClient(apiKey).get<Schedule[]>(
    `/missions/${id}/schedules`
  )

  return schedules
}

export const getAnnounceById = async (
  id: string,
  apiKey?: string
): Promise<Announce> => {
  const { data: announce } = await getApiClient(apiKey).get<Announce>(
    `/announces/${id}`
  )

  return announce
}

export const getMissionById = async (
  id: string,
  apiKey?: string
): Promise<Mission> => {
  const { data: mission } = await getApiClient(apiKey).get<Mission>(
    `/missions/${id}`
  )

  return mission
}

export const createScheduleWithAnnounceId = async (
  announceId: string,
  scheduleDto: CreateScheduleDto,
  apiKey?: string
): Promise<Schedule> => {
  const schedule = getApiClient(apiKey).post<Schedule, Schedule>(
    `/announces/${announceId}/schedules`,
    scheduleDto
  )

  return schedule
}

export const searchAnnounceByReference = async (
  reference: string
): Promise<Announce[]> => {
  const { data: announces } = await getApiClient().get<Announce[]>(
    `/announces/search-by-ref/${reference}`
  )

  return announces ?? []
}

export const createScheduleWithMissionId = async (
  missionId: string,
  scheduleDto: CreateScheduleDto,
  apiKey?: string
): Promise<Schedule> => {
  return getApiClient(apiKey).post<Schedule, Schedule>(
    `/missions/${missionId}/schedules`,
    scheduleDto
  )
}
