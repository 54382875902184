import { Autocomplete, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

export default function ASAutocomplete({
  asList,
  setSelectedAS,
  selectedAS
}: any) {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (!selectedAS?.name) {
      setValue('')

      return
    }

    setValue(`${selectedAS.name} - ${selectedAS.id}`)
  }, [selectedAS])

  return (
    <Autocomplete
      freeSolo
      fullWidth
      options={asList.map((as: any) => `${as.name} - ${as.id}`)}
      onChange={(e, value) => {
        const AS = asList.find((as: any) => `${as.name} - ${as.id}` === value)

        setSelectedAS(AS || {})
      }}
      onInputChange={(e, newValue) => {
        if (newValue !== value) {
          setValue(newValue)
        }
      }}
      inputValue={value}
      renderInput={params => (
        <TextField {...params} fullWidth label={'AS référent'} />
      )}
    />
  )
}
