import {
  AnnounceDto,
  CareGiverDto,
  MissionDto,
  QuoteDto,
  RealScheduleEstimateDto,
  SubstitutionDto
} from 'types/dto'
import {
  Announce,
  CareGiver,
  Mission,
  Person,
  Quote,
  RealScheduleEstimate,
  Substitution,
  Tarif
} from 'types/entity'
import { ContractType, QUOTE_STATUS, STATUS } from 'types/enum'

export const parseRealScheduleEstimateDto = (
  realScheduleDto: RealScheduleEstimateDto
): RealScheduleEstimate => {
  const {
    startDate,
    endDate,
    mission,
    substitution,
    announce,
    prices,
    ...rest
  } = realScheduleDto

  return {
    ...rest,
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    mission: mission ? parseMissionDto(mission) : undefined,
    substitution: substitution ? parseSubstitutionDto(substitution) : undefined,
    announce: announce ? parseAnnounceDto(announce) : undefined,
    prices: prices as Tarif[],
    type: ''
  }
}

export const parseSubstitutionDto = (
  substitutionDto: SubstitutionDto
): Substitution => {
  const { initialMission, startDate, endDate, ...rest } = substitutionDto

  return {
    ...rest,
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    initialMission: parseMissionDto(initialMission)
  }
}

export const parseAnnounceDto = (announceDto: AnnounceDto): Announce => {
  const {
    startDate,
    endDate,
    schedule,
    beneficiary,
    status,
    contractType,
    reference,
    ...rest
  } = announceDto

  return {
    ...rest,
    contractType: parseContractTypeFromString(contractType),
    status: parseStatusFromString(status),
    startDate: new Date(startDate),
    endDate: endDate ? new Date(endDate) : undefined,
    beneficiary: { zone: beneficiary?.zone },
    reference: announceDto.reference
  }
}

export const parseMissionDto = (missionDto: MissionDto): Mission => {
  const { startDate, endDate, announce, careGiver, ...rest } = missionDto

  return {
    ...rest,
    startDate: new Date(startDate),
    endDate: endDate ? new Date(endDate) : undefined,
    announce: parseAnnounceDto(announce),
    careGiver: parseCareGiverDto(careGiver)
  }
}

export const parseQuoteDto = (quoteDto: QuoteDto): Quote => {
  const {
    missionStart,
    missionEnd,
    date,
    validityDate,
    schedules,
    contractType,
    services,
    status,
    ...rest
  } = quoteDto

  return {
    ...rest,
    missionStart: new Date(missionStart),
    missionEnd: missionEnd ? new Date(missionEnd) : undefined,
    date: date ? new Date(date) : undefined,
    validityDate: validityDate ? new Date(validityDate) : undefined,
    contractType: contractType
      ? parseEnumFromString(contractType, ContractType)
      : undefined,
    status: parseEnumFromString(status, QUOTE_STATUS)
  }
}

export const parseCareGiverDto = (careGiverDto: CareGiverDto): CareGiver => {
  const { person, startDateCriteria, ...rest } = careGiverDto

  return {
    ...rest,
    startDateCriteria: startDateCriteria ? new Date(startDateCriteria) : null,
    person: careGiverDto.person as Person
  }
}

/** @deprecated */
export const parseStatusFromString = (statusString: string): STATUS => {
  return Object.values(STATUS).find(status => status === statusString) as STATUS
}

/** @deprecated */
export const parseContractTypeFromString = (
  contractTypeString: string
): ContractType => {
  return Object.values(ContractType).find(
    ContractType => ContractType === contractTypeString
  ) as ContractType
}

export const parseEnumFromString = <T extends { [key: string]: string }>(
  stringToParse: string,
  enumObj: T
): T[keyof T] => {
  const values = Object.values(enumObj) as string[]

  return values.find(value => value === stringToParse) as T[keyof T]
}
