import axios, { Axios } from 'axios'
import { getAccessToken } from './token'

export const getApiClient = (apiKey?: string, token?: string): Axios => {
  const accessToken = getAccessToken()

  return axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
    headers: {
      authorization: `Bearer ${token || accessToken}`,
      'api-key': apiKey
    }
  })
}
