import React, { useState } from 'react'
import {
  MuiTelInput,
  MuiTelInputContinent,
  MuiTelInputCountry,
  MuiTelInputInfo,
  matchIsValidTel
} from 'mui-tel-input'
import { FormikProps } from 'formik'

interface PhoneInputProps {
  id: string
  fullWidth?: boolean
  label?: string
  continents?: MuiTelInputContinent[]
  onlyCountries?: MuiTelInputCountry[]
  form: FormikProps<any>
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  id,
  fullWidth,
  label,
  continents,
  onlyCountries,
  form
}) => {
  const [error, setError] = useState(false)
  const handleChange = (value: string, info: MuiTelInputInfo) => {
    setError(!matchIsValidTel(value))
    console.log(info)
    form.setFieldValue(id, info.numberValue)
  }

  return (
    <MuiTelInput
      id={id}
      error={error}
      helperText={error ? 'Numéro de téléphone invalide' : ''}
      defaultCountry={'FR'}
      langOfCountryName={'fr'}
      continents={continents}
      forceCallingCode={true}
      onlyCountries={onlyCountries}
      fullWidth={fullWidth}
      label={label ?? ''}
      value={form.values[id]}
      focusOnSelectCountry
      onChange={handleChange}
    />
  )
}
