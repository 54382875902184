/* eslint-disable @typescript-eslint/no-explicit-any */

import { FieldInputProps, FormikProps } from 'formik'
import { get, has, isArray } from 'lodash'

type useFormikInputReturn<T> = {
  value: T
  error: string | undefined
  hasError: boolean
  setValue: (newValue: T) => void
}

export function useFormikInputByName<InputType>(
  form: FormikProps<any>,
  name: string
): useFormikInputReturn<InputType> {
  if (isArray(form.values)) {
    form.values = form.values.filter(value => value !== undefined)
  }

  const error = get(form.errors, name) as string | undefined
  const value = get(form.values, name) as InputType
  const hasError = has(form.errors, name)

  const setValue = (newValue: InputType): void => {
    form.setFieldValue(name, newValue)
  }

  return {
    value,
    hasError,
    error,
    setValue
  }
}

export function useFormikInput<InputType>(
  form: FormikProps<any>,
  field: FieldInputProps<any>
): useFormikInputReturn<InputType> {
  return useFormikInputByName(form, field.name)
}
