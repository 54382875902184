import { useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../theme'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Vacancies from '../../components/Vacancies/Vacancies'
import Keycloak from 'keycloak-js'
import Nav from '../../components/Nav/Nav'
import Copyright from '../../components/Copyright/Copyright'
import Unauthorized from '../../components/Unauthorized/Unauthorized'

export default function Dashboard({ keycloak }: { keycloak?: Keycloak }) {
  useEffect(() => {
    if (!keycloak) {
      return
    }

    if (!keycloak.authenticated) {
      window.location.assign(keycloak.createLoginUrl())
    }
  }, [keycloak])

  return (
    (keycloak?.authenticated && keycloak.hasRealmRole('show-vacancies') && (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
          <Nav keycloak={keycloak} />
          <Box
            component="main"
            sx={{
              backgroundColor: theme =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto'
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper
                    sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                  >
                    <Vacancies keycloak={keycloak} />
                  </Paper>
                </Grid>
              </Grid>
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    )) || <Unauthorized />
  )
}
