import { InputGroup } from 'components/Calendar/molecules'
import frLocale from 'date-fns/locale/fr'
import { FieldProps } from 'formik'
import { useFormikInput } from 'hooks/useFormikInput'
import { get } from 'lodash'
import React from 'react'
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
const REGEX_MILITARY_TIME = /([01]?[0-9]|2[0-3]):([0-5][0-9])/

interface CalendarTimePickerProps {
  label?: string
  withError?: boolean
  required?: boolean
  minTimeFieldName?: string
  maxTimeFieldName?: string
}

export const timeStringToUnixDate = (timeString: string): number =>
  Date.parse(`01 Jan 1970 ${timeString}:00 GMT+1`)

export const dateToTimeString = (date: Date): string =>
  `${date.toTimeString().split(':')[0]}:${date.toTimeString().split(':')[1]}`

export const CalendarTimePicker: React.FC<
  FieldProps & CalendarTimePickerProps
> = ({
  field,
  form,
  label,
  withError,
  required,
  minTimeFieldName,
  maxTimeFieldName
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, hasError, error } = useFormikInput<string>(form, field)

  const onChange = (date: unknown, textFieldInput: string | undefined) => {
    if (date === null) {
      form.setFieldValue(field.name, null)

      return
    }

    // Case time = 12:1. Javascript don't throw type error cast it as 12:10 so we have to process this error
    if (
      textFieldInput !== undefined &&
      !REGEX_MILITARY_TIME.test(textFieldInput)
    ) {
      form.setFieldError(
        field.name,
        "Mauvais format d'heure (format attendu hh:mm)"
      )

      return
    }

    const selecteDate = date as Date

    form.setFieldValue(field.name, dateToTimeString(selecteDate))
  }

  return (
    <InputGroup
      error={error}
      label={label}
      withError={withError}
      required={required}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={frLocale}
      >
        <TimePicker
          minTime={
            minTimeFieldName !== undefined
              ? new Date(
                  timeStringToUnixDate(get(form.values, minTimeFieldName))
                )
              : undefined
          }
          maxTime={
            maxTimeFieldName !== undefined
              ? new Date(
                  timeStringToUnixDate(get(form.values, maxTimeFieldName))
                )
              : undefined
          }
          value={new Date(timeStringToUnixDate(value))}
          onChange={(date: Date | null) => onChange(date, this)}
        />
      </LocalizationProvider>
    </InputGroup>
  )
}
