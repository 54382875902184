import { InputGroup } from 'components/Calendar/molecules'
import { FieldProps } from 'formik'
import { useFormikInput } from 'hooks/useFormikInput'
import React from 'react'
import { DAY_FR, Day, DaysList } from 'types/enum'
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  FormControl
} from '@mui/material'

interface DaysPickerProps {
  label?: string
  withError?: boolean
  required?: boolean
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export const DaysPicker: React.FC<FieldProps & DaysPickerProps> = ({
  field,
  form,
  label,
  withError,
  required
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, hasError, error } = useFormikInput<Day[]>(form, field)

  return (
    <>
      <InputGroup
        error={error}
        label={label}
        withError={withError}
        required={required}
      >
        <FormControl sx={{ width: 300, marginBottom: 3 }}>
          <Select
            value={value}
            onChange={event => {
              const eventValue = event.target.value as (Day | "select-all")[]

              if (eventValue.indexOf('select-all') !== -1) {
                if (value.length === 7) {
                  form.setFieldValue(field.name, [])
                } else {
                  form.setFieldValue(field.name, DaysList)
                }

                return
              }

              form.setFieldValue(field.name, event.target.value)
            }}
            label="Jour"
            multiple
            renderValue={selected => selected.map(d => DAY_FR[d]).join(', ')}
            MenuProps={MenuProps}
            variant="standard"
          >
            <MenuItem value={'select-all'} key={'select-all'}>
              <Checkbox checked={value.length === 7} />
              <ListItemText primary="Tous les jours" />
            </MenuItem>
            {Object.values(Day).map(day => {
              return (
                <MenuItem value={day} key={day}>
                  <Checkbox checked={value.indexOf(day) > -1} />
                  <ListItemText primary={DAY_FR[day]} />
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </InputGroup>
    </>
  )
}
