import { ThemeProvider } from '@mui/material/styles'
import theme from '../../theme'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Copyright from '../../components/Copyright/Copyright'
import { useLocation, useParams } from 'react-router-dom'
import { Planning } from 'components/Calendar/template'
import { EntityType } from 'types/entity'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'
import { useQuery } from '@tanstack/react-query'
import { getEntityStartDate, getStartingEntity } from './helpers'

export default function ScheduleEditor() {
  const { entityType, entityId } = useParams()

  const { search } = useLocation()
  const apiKey = new URLSearchParams(search).get('apikey')

  const {
    data: entity,
    error,
    isFetching
  } = useQuery({
    queryKey: ['entityType', entityId],
    queryFn: () => getStartingEntity(entityType, entityId, apiKey || '')
  })

  const contractType =
    entity && 'contractType' in entity ? entity.contractType : undefined

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: currentTheme =>
              currentTheme.palette.mode === 'light'
                ? currentTheme.palette.grey[100]
                : currentTheme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              {entityId && entityType && (
                <>
                  {isFetching ? (
                    <CircularProgress color="secondary" />
                  ) : !error ? (
                    entity && (
                      <Planning
                        editable={
                          !['application', 'quote'].includes(entityType)
                        }
                        entityId={entityId}
                        entityType={entityType as EntityType}
                        contractType={contractType}
                        startDate={getEntityStartDate(
                          entityType as EntityType,
                          entity
                        )}
                      />
                    )
                  ) : (
                    <p>Une erreur est survenue : {error.message}</p>
                  )}
                </>
              )}
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Copyright sx={{ pt: 4 }} />
              </Container>
            </Paper>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
