import { getAnnounceById, getMissionById, getQuoteById } from 'adapters/backend'
import { getApplicationById } from 'adapters/backend/application'
import { getBeneficiaryId } from 'adapters/backend/beneficiary'
import { getLeadById } from 'adapters/backend/lead'
import {
  Announce,
  Application,
  Beneficiary,
  EntityType,
  isEntityType,
  Lead,
  Mission,
  Quote
} from 'types/entity'

type Entity = Lead | Beneficiary | Announce | Application | Mission | Quote

export const getStartingEntity = (
  entityType: string | undefined,
  entityId: string | undefined,
  apiKey?: string
): Promise<Entity> => {
  if (!entityId || !isEntityType(entityType)) {
    throw new Error("L'entité n'est pas prise en charge")
  }

  switch (entityType) {
    case 'lead':
      return getLeadById(entityId, apiKey)
    case 'beneficiary':
      return getBeneficiaryId(entityId, apiKey)
    case 'announce':
      return getAnnounceById(entityId, apiKey)
    case 'application':
      return getApplicationById(entityId, apiKey)
    case 'mission':
      return getMissionById(entityId, apiKey)
    case 'quote':
      return getQuoteById(entityId, apiKey)
    default:
      throw new Error("L'entité n'est pas prise en charge.")
  }
}

export const getEntityStartDate = (entityType: EntityType, entity: Entity) => {
  switch (entityType) {
    case 'lead':
      return (entity as Lead).announce.startDate
    case 'beneficiary':
      return (entity as Beneficiary).familyAnnounces?.[0].startDate
    case 'announce':
      return (entity as Announce).startDate
    case 'application':
      return (entity as Application).announce.startDate
    case 'mission':
      return (entity as Mission).startDate
    case 'quote':
      return (entity as Quote).missionStart
    default:
      return undefined
  }
}
