import { getApiClient } from 'adapters/backend/network'
import {
  LeadDetailsDto,
  PipedriveDealDto,
  PipedriveOrgDto,
  PipedrivePersonDto
} from 'types/dto'

export const getAllPipedriveLeads = async (): Promise<PipedriveDealDto[]> => {
  const { data: leads } = await getApiClient().get<PipedriveDealDto[]>(
    `/leads/pipedrive`
  )

  return leads
}

export const getPipedriveLeadDetails = async (
  dealId: string
): Promise<LeadDetailsDto> => {
  const { data: leads } = await getApiClient().get<LeadDetailsDto>(
    `/leads/pipedrive/${dealId}/details`
  )

  return leads
}

export const searchPipedriveBTOBOrgs = async (optionalToken?: string): Promise<PipedriveOrgDto[]> => {
  const { data: leads } = await getApiClient(undefined, optionalToken).get<PipedriveOrgDto[]>(
    `/leads/pipedrive/orgs/b2b2c`
  )

  return leads
}

export const searchPipedriveBTOBAS = async (
  orgId: string
): Promise<PipedrivePersonDto[]> => {
  const { data: leads } = await getApiClient().get<PipedrivePersonDto[]>(
    `/leads/pipedrive/orgs/b2b2c/${orgId}/persons`
  )

  return leads || []
}
