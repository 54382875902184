import { EventInput } from '@fullcalendar/common'
import { RealScheduleEstimate, Schedule, Tarif, TotalTarif } from 'types/entity'
import styled from '@emotion/styled'
import { DateTime, Interval } from 'luxon'
import { DAY_JS } from 'types/enum'

export const CalendarViewContainer = styled.div`
  max-height: 2000;
`

export const stringToColour = (
  str: string,
  saturation = 80,
  lightness = 75
) => {
  let hash = 0

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }

  return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`
}

export const UNSTAFFED_EVENT_COLOR_CONFIG = {
  backgroundColor: '#DCDCDC',
  textColor: '#333'
}

const getTitle = (schedule: Schedule) => {
  if (schedule.type === 'quote') {
    return ''
  }

  return schedule.mission?.careGiver
    ? `${schedule.mission?.careGiver.person.firstName} ${schedule.mission?.careGiver.person.lastName}`
    : 'Non staffé'
}

const getEndDate = (schedule: Schedule, calendarDate: Date) => {
  const baseEndDate = new Date(
    schedule.mission?.endDate ??
      schedule.substitution?.endDate ??
      schedule.announce?.endDate ??
      new Date('2100-01-01')
  ).getTime()
  const defaultEndDate = DateTime.fromJSDate(calendarDate)
    .plus({ weeks: 5 })
    .toMillis()

  return DateTime.fromMillis(Math.min(baseEndDate, defaultEndDate)).plus({
    days: 1
  })
}

export const convertSchedulesToEvents = (
  schedules: Schedule[] | undefined,
  startCalendarDate?: Date
): EventInput[] | undefined => {
  if (!schedules || !startCalendarDate) {
    return undefined
  }

  return schedules.map(schedule => {
    const careGiverId = schedule.mission?.careGiver?.id
    const backgroundColor = careGiverId
      ? stringToColour(careGiverId)
      : UNSTAFFED_EVENT_COLOR_CONFIG.backgroundColor
    const textColor = careGiverId
      ? 'black'
      : UNSTAFFED_EVENT_COLOR_CONFIG.textColor

    const [start, end] = getFirstDateRangeForSchedule(
      startCalendarDate,
      schedule
    )

    return {
      id: schedule.id,
      rrule: {
        dtstart: start,
        freq: 'weekly',
        until: getEndDate(schedule, startCalendarDate).toISO()
      },
      duration: computeDuration(start, end),
      title: getTitle(schedule),
      contractType: schedule.contractType,
      backgroundColor,
      textColor,
      borderColor: backgroundColor,
      groupId: schedule.id
    }
  })
}

const getDayOffset = (start: number, end: number) => {
  if (start <= end) {
    return end - start
  }

  return 7 - start + end
}

export const getFirstDateRangeForSchedule = (
  currentCalendarDate: Date,
  schedule: Schedule
) => {
  const entityStartDate = new Date(
    schedule.mission?.startDate ?? schedule.substitution?.startDate ?? 0
  )

  const entityStartDateDT = DateTime.fromJSDate(entityStartDate)
  const baseStartDateDT = DateTime.fromJSDate(currentCalendarDate).minus({
    weeks: 1
  })

  const startDate =
    entityStartDateDT.toMillis() > baseStartDateDT.toMillis()
      ? entityStartDateDT
      : baseStartDateDT

  // SUNDAY = 0
  const dayIndex = DAY_JS.indexOf(schedule.day)
  const endDayIndex = DAY_JS.indexOf(schedule.endDay ?? schedule.day)
  const dayOffset = getDayOffset(dayIndex, endDayIndex)

  const startDayOffset = getDayOffset(startDate.weekday % 7, dayIndex)

  const firstDate = startDate.plus({ days: startDayOffset })
  const lastDate = firstDate.plus({ days: dayOffset })

  const start = toRealDate(firstDate, schedule.startHour)
  const end = toRealDate(lastDate, schedule.endHour)

  return [start, end]
}

const toRealDate = (date: DateTime, time: string) => {
  const dateString = date.toFormat('yyyy-MM-dd')

  return `${dateString}T${time}Z`
}

function computeDuration(start: string, end: string) {
  const duration = Interval.fromISO(`${start}/${end}`).toDuration([
    'days',
    'hours',
    'minutes'
  ])

  return duration
}

export const getTotalsFromRealScheduleEstimates = (
  realScheduleEstimates: RealScheduleEstimate[]
): TotalTarif => {
  if (!realScheduleEstimates || realScheduleEstimates.length === 0) {
    return {
      candCTaxExcludedAmount: 0,
      candCVATAmount: 0,
      careGiverTaxExcludedAmount: 0,
      careGiverVATAmount: 0,
      travelFees: 0,
      hoursBilled: 0,
      totalTaxIncluded: 0,
      globalFees: 0
    }
  }

  const priceArray: Tarif[] = []

  realScheduleEstimates.forEach(estimate => {
    priceArray.push(...estimate.prices)
  })

  return priceArray.reduce<TotalTarif>(
    (total, tarif) => ({
      candCTaxExcludedAmount:
        total.candCTaxExcludedAmount + tarif.candCTaxExcludedAmount,
      candCVATAmount: total.candCVATAmount + tarif.candCVATAmount,
      careGiverTaxExcludedAmount:
        total.careGiverTaxExcludedAmount + tarif.careGiverTaxExcludedAmount,
      careGiverVATAmount: total.careGiverVATAmount + tarif.careGiverVATAmount,
      travelFees: total.travelFees + tarif.travelFees,
      hoursBilled: total.hoursBilled + tarif.hoursBilled,
      totalTaxIncluded:
        total.totalTaxIncluded +
        tarif.candCTaxExcludedAmount +
        tarif.candCVATAmount +
        tarif.careGiverTaxExcludedAmount +
        tarif.careGiverVATAmount +
        tarif.travelFees,
      globalFees: total.globalFees + tarif.globalFees
    }),
    {
      candCTaxExcludedAmount: 0,
      candCVATAmount: 0,
      careGiverTaxExcludedAmount: 0,
      careGiverVATAmount: 0,
      travelFees: 0,
      hoursBilled: 0,
      totalTaxIncluded: 0,
      globalFees: 0
    }
  )
}
