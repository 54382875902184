import { getApiClient } from 'adapters/backend/network'
import { CreateScheduleDto, QuoteDto, UpdateQuoteDto } from 'types/dto'
import { Quote, Schedule } from 'types/entity'
import { parseQuoteDto } from 'utils/parsers'

export const getAllQuotesFromPipedriveId = async (
  id: string
): Promise<QuoteDto[]> => {
  const { data: quotes } = await getApiClient().get<QuoteDto[]>(
    `/quotes?pipedriveid=${id}`
  )

  return quotes
}

export const createQuote = async (pipedriveId: string): Promise<QuoteDto> => {
  const { data: quote } = await getApiClient().post<QuoteDto>(`/quotes`, {
    pipedriveId
  })

  return quote
}

export const validateQuote = async (quoteId: string): Promise<QuoteDto> => {
  const { data: quote } = await getApiClient().post<QuoteDto>(
    `/quotes/${quoteId}/validate`,
    {}
  )

  return quote
}

export const duplicateQuote = async (quoteId: string): Promise<QuoteDto> => {
  const { data: quote } = await getApiClient().post<QuoteDto>(
    `/quotes/${quoteId}/duplicate`,
    {}
  )

  return quote
}

export const updateQuote = async ({
  id,
  updateQuoteDto
}: {
  id: string
  updateQuoteDto: UpdateQuoteDto
}): Promise<QuoteDto> => {
  const { data: quote } = await getApiClient().patch<QuoteDto>(
    `/quotes/${id}`,
    updateQuoteDto
  )

  return quote
}

export const getQuoteById = async (
  id: string,
  apiKey?: string
): Promise<Quote> => {
  const { data: quote } = await getApiClient(apiKey).get<QuoteDto>(
    `/quotes/${id}`
  )

  return parseQuoteDto(quote)
}

export const getAllSchedulesByQuoteId = async (
  id: string
): Promise<Schedule[]> => {
  const { data: schedules } = await getApiClient().get<Schedule[]>(
    `/quotes/${id}/schedules`
  )

  return schedules
}

export const createScheduleWithQuoteId = async (
  quoteId: string,
  scheduleDto: CreateScheduleDto
): Promise<Schedule> => {
  const createQuoteScheduleDto = { quoteId, ...scheduleDto }
  const schedule = getApiClient().post<Schedule, Schedule>(
    `/quotes/${quoteId}/schedules`,
    createQuoteScheduleDto
  )

  return schedule
}

export const getQuoteS3Url = async (quoteId: string): Promise<string> => {
  const { data: s3url } = await getApiClient().get<{ url: string }>(
    `/quotes/${quoteId}/predownload`
  )

  return s3url.url
}
