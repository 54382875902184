import { getApiClient } from './network'
import { Announce, Application, Schedule } from 'types/entity'

export const getAllSchedulesByApplicationId = async (
  id: string,
  apiKey?: string
): Promise<Schedule[]> => {
  const { data: schedules } = await getApiClient(apiKey).get<Schedule[]>(
    `/applications/${id}/schedules`
  )

  return schedules
}

export const getApplicationById = async (
  id: string,
  apiKey?: string
): Promise<Application> => {
  const { data: announce } = await getApiClient(apiKey).get<Application>(
    `/applications/${id}`
  )

  return announce
}

export let announce: Announce
