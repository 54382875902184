import { Box, Container, Paper } from '@mui/material'

export const Loading = () => {
  return (
    <Box>
      <Container maxWidth="lg">
        <Box flex={1} textAlign={'center'} padding={2}>
          <img
            src="/img/logo-click-and-care-color.png"
            style={{
              display: 'inline-block',
              width: '350px',
              textAlign: 'center'
            }}
            alt="logo"
          />
        </Box>
        <Paper
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          En cours de chargement
        </Paper>
      </Container>
    </Box>
  )
}
