import { ThemeProvider } from '@mui/material/styles'
import theme from '../../theme'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Copyright from '../../components/Copyright/Copyright'
import { CheckCircleOutline } from '@mui/icons-material'

export default function ConflictHD() {

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: currentTheme =>
              currentTheme.palette.mode === 'light'
                ? currentTheme.palette.grey[100]
                : currentTheme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            paddingTop: '66px'
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
            <Grid container spacing={3} marginBottom={8}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
              <img
                  src="/img/logo-click-and-care-color.png"
                  style={{
                    display: 'inline-block',
                    width: '350px',
                    textAlign: 'center'
                  }}
                  alt="logo"
                />
                <br />
                <br />
                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                  <Box><CheckCircleOutline sx={{ fontSize: 100, color: currentTheme => currentTheme.palette.primary.main }} /></Box>
                  <br />
                  <Box style={{ fontSize: 20,  }}>Votre demande de litige a bien été envoyé à notre équipe</Box>
                </Box>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
