import { format } from 'date-fns'
import { getApiClient } from 'adapters/backend/network'
import {
  ScheduleDto,
  CreateScheduleDto,
  RealScheduleEstimateDto
} from 'types/dto'
import { Tarif, Schedule, EntityType } from 'types/entity'
import {
  createScheduleWithAnnounceId,
  createScheduleWithMissionId,
  getAllSchedulesByAnnounceId,
  getAllSchedulesByMissionId
} from './announce'
import { createScheduleWithQuoteId, getAllSchedulesByQuoteId } from './quote'
import {
  createScheduleWithBeneficiaryId,
  getAllSchedulesByBeneficiaryId
} from './beneficiary'
import { createScheduleWithLeadId, getAllSchedulesByLeadId } from './lead'
import { getAllSchedulesByApplicationId } from './application'

export const deleteSchedule = async (
  id: string,
  apiKey?: string
): Promise<void> => {
  await getApiClient(apiKey).delete(`/schedules/${id}`)
}

export const updateSchedule = async (
  id: string,
  schedule: ScheduleDto,
  apiKey?: string
): Promise<Schedule> => {
  const { data: updatedSchedule } = await getApiClient(apiKey).patch<Schedule>(
    `/schedules/${id}`,
    schedule
  )

  return updatedSchedule
}

export const getSchedules = async (
  entityId: string,
  entityType: EntityType,
  apiKey?: string
) => {
  switch (entityType) {
    case 'announce':
      return getAllSchedulesByAnnounceId(entityId, apiKey)
    case 'quote':
      return getAllSchedulesByQuoteId(entityId)
    case 'beneficiary':
      return getAllSchedulesByBeneficiaryId(entityId, apiKey)
    case 'mission':
      return getAllSchedulesByMissionId(entityId, apiKey)
    case 'lead':
      return getAllSchedulesByLeadId(entityId, apiKey)
    case 'application':
      return getAllSchedulesByApplicationId(entityId, apiKey)
  }
}

export const createSchedule = async (
  entityId: string,
  entityType: EntityType,
  createScheduleDto: CreateScheduleDto,
  apiKey?: string
) => {
  switch (entityType) {
    case 'announce':
      await createScheduleWithAnnounceId(entityId, createScheduleDto, apiKey)
      break
    case 'quote':
      await createScheduleWithQuoteId(entityId, createScheduleDto)
      break
    case 'beneficiary':
      await createScheduleWithBeneficiaryId(entityId, createScheduleDto, apiKey)
      break
    case 'mission':
      await createScheduleWithMissionId(entityId, createScheduleDto, apiKey)
      break
    case 'lead':
      await createScheduleWithLeadId(entityId, createScheduleDto, apiKey)
  }
}

export const getScheduleEstimates = async (
  id: string,
  currentDate: Date
): Promise<Tarif[]> => {
  if (!currentDate) {
    return []
  }

  const date = format(currentDate, 'yyyy-MM-dd')
  const { data: tarifs } = await getApiClient().get<Tarif[]>(
    `/schedules/${id}/estimate?currentDate=${date}`
  )

  return tarifs
}

export const getRealScheduleEstimatesByAnnounce = async (
  announceId: string,
  startDate: string,
  endDate: string
): Promise<RealScheduleEstimateDto[]> => {
  const { data: estimates } = await getApiClient().get<
    RealScheduleEstimateDto[]
  >(`/announces/${announceId}/estimate/${startDate}/${endDate}`)

  return estimates
}

export const getRealScheduleEstimatesByQuote = async (
  quoteId: string,
  startDate: string,
  endDate: string
): Promise<RealScheduleEstimateDto[]> => {
  const { data: estimates } = await getApiClient().get<
    RealScheduleEstimateDto[]
  >(`/quotes/${quoteId}/estimate/${startDate}/${endDate}`)

  return estimates
}

export const getRealScheduleEstimatesByBeneficiary = async (
  beneficiaryId: string,
  startDate: string,
  endDate: string
): Promise<RealScheduleEstimateDto[]> => {
  const { data: estimates } = await getApiClient().get<
    RealScheduleEstimateDto[]
  >(`/beneficiary/${beneficiaryId}/estimate/${startDate}/${endDate}`)

  return estimates
}

export const getRealScheduleEstimatesByMission = async (
  missionId: string,
  startDate: string,
  endDate: string
): Promise<RealScheduleEstimateDto[]> => {
  const { data: estimates } = await getApiClient().get<
    RealScheduleEstimateDto[]
  >(`/missions/${missionId}/estimate/${startDate}/${endDate}`)

  return estimates
}

export const getRealScheduleEstimatesByLead = async (
  leadId: string,
  startDate: string,
  endDate: string
): Promise<RealScheduleEstimateDto[]> => {
  const { data: estimates } = await getApiClient().get<
    RealScheduleEstimateDto[]
  >(`/leads/${leadId}/estimate/${startDate}/${endDate}`)

  return estimates
}

export const getRealScheduleEstimatesByApplication = async (
  applicationId: string,
  startDate: string,
  endDate: string
): Promise<RealScheduleEstimateDto[]> => {
  const { data: estimates } = await getApiClient().get<
    RealScheduleEstimateDto[]
  >(`/applications/${applicationId}/estimate/${startDate}/${endDate}`)

  return estimates
}
