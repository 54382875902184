/* eslint-disable max-lines */
import Dialog from '@mui/material/Dialog'
import { format, isFuture, startOfWeek } from 'date-fns'
import { Formik } from 'formik'
import {
  getScheduleEstimateQueryKey,
  useCreateScheduleMutation,
  useDeleteSchedule,
  useSchedules
} from 'hooks/index'
import { useEffect, useState } from 'react'
import { EntityType, ScheduleForm } from 'types/entity'
import { ScheduleDto } from 'types/dto'
import { Calendar } from '../Calendar'
import EditableCalendarDialogModal from './DialogModal'
import {
  scheduleFormValidationSchema,
  extractDayFromUTCDate,
  zeroNumber,
  getFrDayOfweek
} from 'utils/tools'
import { EventDropArg } from '@fullcalendar/common'
import { EventResizeDoneArg } from '@fullcalendar/interaction'
import { ContractType, Day } from 'types/enum'
import Keycloak from 'keycloak-js'
import { useScheduleContext } from 'hooks/scheduleContext.hook'
import {
  fromEventToScheduleDto,
  getPlanningTitle,
  makeSchedules
} from './helpers'

import './hideTodayIndicator.css'
import { AlertDialog } from './AlertDialog'

import { useQueryClient } from '@tanstack/react-query'
import { updateSchedule } from 'adapters/backend'
import { useLocation } from 'react-router-dom'

type ErrorLabel = 'scheduleEdition' | 'scheduleCreation'

const errorDialogContent: Record<
  ErrorLabel,
  { title: string; content: string }
> = {
  scheduleEdition: {
    title: 'Créneau non modifiable',
    content: "Une erreur est survenue lors de l'édition du créneau."
  },
  scheduleCreation: {
    title: 'Erreur lors de la création du créneau',
    content: "Le créneau n'a pas été ajouté au planning"
  }
}

const contractTypeToScheduleCType = (
  ctype: ContractType | undefined
): ContractType | undefined => {
  if (ctype === ContractType.MIX) {
    return undefined
  }

  if (ctype === ContractType.REPRESENTATIVE) {
    return ContractType.CDI
  }

  return ctype
}

interface PlanningProps {
  entityId: string
  entityType: EntityType
  editable: boolean
  contractType?: ContractType
  keycloak?: Keycloak
  isGenericWeek?: boolean
  startDate?: Date
}

export const Planning = ({
  entityType,
  entityId,
  editable,
  contractType,
  isGenericWeek,
  startDate
}: PlanningProps) => {
  const queryClient = useQueryClient()
  const { search } = useLocation()
  const apiKey = new URLSearchParams(search).get('apikey') || ''

  const startOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 })

  const initialDate =
    startDate && isFuture(new Date(startDate)) ? startDate : startOfCurrentWeek
  const [calendarStartDate, setCalendarStartDate] = useState(initialDate)

  const { mutate: deleteSchedule } = useDeleteSchedule()
  const { mutate: createSchedule } = useCreateScheduleMutation()

  const {
    data: schedules,
    isLoading,
    error,
    refetch
  } = useSchedules(entityId, entityType)

  const { updateScheduleData } = useScheduleContext()

  useEffect(() => {
    updateScheduleData(schedules)
  }, [schedules])

  useEffect(() => {
    if (entityType !== 'quote') {
      return
    }

    if (
      contractType === ContractType.CDI ||
      contractType === ContractType.REPRESENTATIVE
    ) {
      Promise.all(
        schedules?.map(schedule =>
          updateSchedule(
            schedule.id,
            {
              ...schedule,
              contractType: ContractType.CDI
            } as ScheduleDto,
            apiKey
          )
        ) || []
      ).then(() => {
        queryClient
          .invalidateQueries({
            queryKey: [
              getScheduleEstimateQueryKey(
                entityId,
                format(calendarStartDate, 'yyyy-MM-dd')
              )
            ]
          })
          .then(() => refetch())
      })

      return
    }

    if (contractType === ContractType.LIBERAL) {
      Promise.all(
        schedules?.map(schedule =>
          updateSchedule(
            schedule.id,
            {
              ...schedule,
              contractType: ContractType.LIBERAL
            } as ScheduleDto,
            apiKey
          )
        ) || []
      ).then(() => {
        queryClient
          .invalidateQueries({
            queryKey: [
              getScheduleEstimateQueryKey(
                entityId,
                format(calendarStartDate, 'yyyy-MM-dd')
              )
            ]
          })
          .then(() => refetch())
      })

      return
    }
  }, [contractType, entityType])

  const [alertMessageOpen, setAlertMessageOpen] = useState<
    ErrorLabel | undefined
  >(undefined)

  const [initialData, setInitialData] = useState<ScheduleForm>({
    id: undefined,
    startHour: undefined,
    endHour: undefined,
    day: undefined,
    endDay: undefined,
    contractType: undefined,
    days: []
  })
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const onSubmit = async (scheduleData: ScheduleForm) => {
    try {
      const schedules = makeSchedules(scheduleData)

      await Promise.all(
        schedules.map(schedule =>
          createSchedule({
            entityId,
            entityType,
            createScheduleDto: schedule as ScheduleDto
          })
        )
      )

      setIsCreateModalOpen(false)
      refetch()
    } catch (err) {
      setAlertMessageOpen('scheduleCreation')
    }
  }

  const onUpdateSchedule = async (
    arg: EventDropArg | EventResizeDoneArg
  ): Promise<void> => {
    try {
      await updateSchedule(arg.event.id, fromEventToScheduleDto(arg), apiKey)
      await queryClient.invalidateQueries({ queryKey: ['schedules'] })
    } catch (err) {
      arg.revert()
      setAlertMessageOpen('scheduleEdition')
    }
  }

  if (!schedules && isLoading) {
    return <p>Chargement: récupération du planning...</p>
  }

  if (error) {
    return <p>Erreur: le planning ne peut être récupéré.</p>
  }

  const addButton = {
    text: ' + Ajouter',
    click: () => {
      setInitialData({
        startHour: '12:00',
        endHour: '14:00',
        day: Day.MONDAY,
        endDay: Day.MONDAY,
        contractType: contractTypeToScheduleCType(contractType),
        days: [Day.MONDAY]
      })
      setIsCreateModalOpen(true)
    }
  }

  const onSelect = ({ start, end }: { start: Date; end: Date }) => {
    const day = extractDayFromUTCDate(start)
    const endDay = extractDayFromUTCDate(end)

    const startHour =
      zeroNumber(start.getUTCHours()) + ':' + zeroNumber(start.getUTCMinutes())
    const endHour =
      zeroNumber(end.getUTCHours()) + ':' + zeroNumber(end.getUTCMinutes())

    setInitialData({
      ...initialData,
      day,
      endDay,
      startHour,
      endHour,
      contractType: contractTypeToScheduleCType(contractType),
      days: [day],
      isMultiDay: day !== endDay
    })

    if (contractType && contractType !== ContractType.MIX) {
      onSubmit({
        ...initialData,
        day,
        endDay,
        startHour,
        endHour,
        contractType:
          contractType === ContractType.REPRESENTATIVE
            ? ContractType.CDI
            : contractType,
        days: []
      })

      return
    }

    setIsCreateModalOpen(true)
  }

  return (
    <>
      <Calendar
        schedules={schedules}
        snapDuration={{ minute: 15 }}
        customButtons={editable ? { addButton } : {}}
        eventContentProps={{
          onDelete: editable ? deleteSchedule : null,
          entityType,
          editable
        }}
        titleFormat={() => getPlanningTitle(isGenericWeek, calendarStartDate)}
        nowIndicator={false}
        headerToolbar={{
          center: 'title',
          left: editable ? 'addButton' : '',
          end: isGenericWeek ? '' : 'today prev,next'
        }}
        editable={editable}
        selectable={editable}
        eventDurationEditable={editable}
        eventResizableFromStart={editable}
        aspectRatio={1}
        dayHeaderContent={arg =>
          getFrDayOfweek(arg.date.getDay()) +
          (isGenericWeek ? '' : ' ' + arg.date.getDate())
        }
        initialDate={calendarStartDate}
        eventDrop={onUpdateSchedule}
        eventResize={onUpdateSchedule}
        setStartDate={setCalendarStartDate}
        select={onSelect}
      />

      <Dialog open={isCreateModalOpen} fullWidth={true} maxWidth={'sm'}>
        <Formik
          initialValues={initialData}
          onSubmit={onSubmit}
          validationSchema={scheduleFormValidationSchema()}
          validateOnMount={true}
        >
          {({
            isValid: canSubmit,
            handleSubmit,
            values: { startHour, endHour }
          }) => (
            <EditableCalendarDialogModal
              canSubmit={canSubmit}
              handleSubmit={handleSubmit}
              setIsCreateModalOpen={setIsCreateModalOpen}
              startHour={startHour}
              endHour={endHour}
              contractType={contractTypeToScheduleCType(contractType)}
            />
          )}
        </Formik>
      </Dialog>

      {alertMessageOpen && (
        <AlertDialog
          open={!!alertMessageOpen}
          title={errorDialogContent[alertMessageOpen].title}
          text={errorDialogContent[alertMessageOpen].content}
          onAcknowledge={() => {
            setAlertMessageOpen(undefined)
          }}
        />
      )}
    </>
  )
}
