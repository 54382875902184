export enum ContractType {
  CDI = 'cdi',
  LIBERAL = 'liberal',
  MIX = 'mix',
  REPRESENTATIVE = 'representative'
}

export enum ScheduleContractType {
  CDI = 'cdi',
  LIBERAL = 'liberal'
}

export const CONTRACT_TYPE_FR = {
  [ContractType.CDI]: 'Mode Emploi Direct',
  [ContractType.LIBERAL]: 'Prestataire Libéral',
  [ContractType.MIX]: 'Mixte',
  [ContractType.REPRESENTATIVE]: 'Mandataire'
}

export enum Day {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export const DaysList = [
  Day.MONDAY,
  Day.TUESDAY,
  Day.WEDNESDAY,
  Day.THURSDAY,
  Day.FRIDAY,
  Day.SATURDAY,
  Day.SUNDAY
]

export enum DayNb {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6
}

export const DAY_JS = [
  Day.SUNDAY,
  Day.MONDAY,
  Day.TUESDAY,
  Day.WEDNESDAY,
  Day.THURSDAY,
  Day.FRIDAY,
  Day.SATURDAY
]

export const DAY_FR = {
  [Day.MONDAY]: 'Lundi',
  [Day.TUESDAY]: 'Mardi',
  [Day.WEDNESDAY]: 'Mercredi',
  [Day.THURSDAY]: 'Jeudi',
  [Day.FRIDAY]: 'Vendredi',
  [Day.SATURDAY]: 'Samedi',
  [Day.SUNDAY]: 'Dimanche'
}

export enum STATUS {
  DRAFT = 'draft',
  SEARCH = 'search',
  STAFFED = 'staffed',
  ENDED = 'ended'
}

export enum FAMILY_SERVICES {
  'BED_TIME' = 'bed-time',
  'WASHING_DRESSING' = 'washing-dressing',
  'MOVEMENT' = 'movement',
  'REMINDERS' = 'rappels',
  'FOOD_DELIVERY' = 'food-delivery',
  'ASSISTANCE_WITH_EATING' = 'assistance-with-eating',
  'CLEANING' = 'cleaning',
  'LAUNDRY_IRONING' = 'laundry-ironing',
  'COMPANY_STIMULATION' = 'company-stimulation',
  'NIGHT_WATCH' = 'night-watch'
}

interface FamilyServicesFR {
  [key: string]: string
}

export const FAMILY_SERVICES_FR: FamilyServicesFR = {
  'bed-time': 'Coucher',
  'washing-dressing': 'Toilette / Habillement',
  movement: 'Déplacements',
  rappels: 'Rappels',
  'food-delivery': 'Courses',
  'assistance-with-eating': 'Repas',
  cleaning: 'Ménage',
  'laundry-ironing': 'Linge / Repassage',
  'company-stimulation': 'Compagnie',
  'night-watch': 'Garde de nuit'
}

export enum QUOTE_STATUS {
  INIT = 'init',
  GENERATED = 'generated',
  SENT = 'sent',
  VALIDATED = 'validated',
  DECLINED = 'declined'
}
