import { useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../theme'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Keycloak from 'keycloak-js'
import Nav from '../../components/Nav/Nav'
import Copyright from '../../components/Copyright/Copyright'
import { GradientButton } from '../../components/Button/Button.style'

export default function Home({ keycloak }: { keycloak?: Keycloak }) {
  useEffect(() => {
    if (!keycloak) {
      return
    }

    if (!keycloak.authenticated) {
      window.location.assign(keycloak.createLoginUrl())

      return
    }
  }, [keycloak])

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <Nav keycloak={keycloak} />
        <Box
          component="main"
          sx={{
            backgroundColor: currentTheme =>
              currentTheme.palette.mode === 'light'
                ? currentTheme.palette.grey[100]
                : currentTheme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            paddingTop: '66px'
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Box>Bienvenue sur l'intranet/ extranet Click&Care!</Box>
                  <Box>
                    {!keycloak?.authenticated &&
                      keycloak?.createRegisterUrl() &&
                      keycloak?.createRegisterUrl() !== '' && (
                        <GradientButton
                          onClick={() =>
                            window.location.assign(
                              keycloak?.createRegisterUrl()
                            )
                          }
                        >
                          S'inscrire
                        </GradientButton>
                      )}
                  </Box>
                  <Box>
                    {!keycloak?.authenticated &&
                      keycloak?.createLoginUrl() &&
                      keycloak?.createLoginUrl() !== '' && (
                        <GradientButton
                          onClick={() =>
                            window.location.assign(keycloak?.createLoginUrl())
                          }
                        >
                          Se connecter
                        </GradientButton>
                      )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
