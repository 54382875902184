import axios from 'axios'

export const postFamilyLead = async (body: any, apiKey?: string) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/leads/v2/beneficiary',
      body,
      { headers: { 'api-key': apiKey || 'public' } }
    )

    return Promise.resolve(response.data)
  } catch (e) {
    return Promise.reject({})
  }
}
