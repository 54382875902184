/* eslint-disable react/prop-types */
import { Autocomplete, TextField } from '@mui/material'
import { searchAnnounceByReference } from 'adapters/backend'
import { FormikProps } from 'formik'
import { useState } from 'react'
import { Announce } from 'types/entity'
import Keycloak from 'keycloak-js'
import { useQuery } from '@tanstack/react-query'

interface AnnounceAutocompleteProps {
  id: string
  label?: string
  disabled?: boolean
  searchMode: 'reference' | 'id'
  form: FormikProps<any>
  keycloak?: Keycloak
  postOnChangeEvent?: (value: Announce | null) => void
}

export const AnnounceAutcomplete: React.FC<AnnounceAutocompleteProps> = ({
  id,
  label,
  disabled,
  form,
  searchMode,
  postOnChangeEvent
}) => {
  const [inputValue, setInputValue] = useState('')

  const { data: announces } = useQuery({
    queryKey: ['cities', inputValue],
    queryFn: () => {
      if (searchMode === 'reference') {
        return searchAnnounceByReference(inputValue)
      }

      return []
    }
  })

  return (
    <Autocomplete
      id={id}
      noOptionsText="Aucune announce trouvée"
      disabled={disabled}
      options={announces ?? []}
      filterOptions={x => x} // always show all options
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionKey={(option: Announce) => option.id}
      getOptionLabel={(option: Announce) => `${option.reference}`}
      value={form.values[id]}
      renderInput={params => (
        <TextField
          {...params}
          type="text"
          label={label ?? 'ville'}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue.toString())
      }}
      onChange={(_, value) => {
        form.setFieldValue(id, value)
        if (postOnChangeEvent) {
          postOnChangeEvent(value)
        }
      }}
      onBlur={form.handleBlur}
    />
  )
}
