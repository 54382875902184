import { CreateScheduleDto } from 'types/dto'

import { Beneficiary, Schedule } from 'types/entity'
import { getApiClient } from './network'

export const getAllSchedulesByBeneficiaryId = async (
  id: string,
  apiKey?: string
): Promise<Schedule[]> => {
  const { data: schedules } = await getApiClient(apiKey).get<Schedule[]>(
    `/beneficiary/${id}/schedules`
  )

  return schedules
}

export const getBeneficiaryId = async (
  id: string,
  apiKey?: string
): Promise<Beneficiary> => {
  const { data: beneficiary } = await getApiClient(apiKey).get<Beneficiary>(
    `/beneficiary/${id}`
  )

  return beneficiary
}

export const createScheduleWithBeneficiaryId = async (
  beneficiaryId: string,
  schedule: CreateScheduleDto,
  apiKey?: string
): Promise<void> =>
  getApiClient(apiKey).post(`/beneficiary/${beneficiaryId}/schedules`, schedule)

export let beneficiary: Beneficiary
