import styled from '@emotion/styled'

export const Label = styled.label`
  display: block;

  font-weight: ${({ theme }) => theme.extend.fontWeight.regular};
  font-size: ${({ theme }) => theme.extend.fontSize.small};
  color: ${({ theme }) => theme.extend.palette.grey.medium};

  margin-bottom: ${({ theme }) => theme.extend.getSpacing(2)};
`

export const Error = styled.p`
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: ${({ theme }) => theme.extend.fontWeight.regular};
  font-size: ${({ theme }) => theme.extend.fontSize.xs};
  font-style: italic;

  margin-top: ${({ theme }) => theme.extend.getSpacing(1)};
`

export const Row = styled.div`
  text-align: left;
  width: 100%;
`

export const RequiredText = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
`
