import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery
} from '@mui/material'
import { useTheme } from '@emotion/react'

export default function BeneficiaryLink({ formik }: any) {
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))

  const theme = useTheme()

  return (
    <Grid item xs={12} marginBottom={'25px'}>
      <Typography
        component="h3"
        variant={isSm ? 'h6' : 'h4'}
        color="primary"
        display="inline-block"
        lineHeight="unset"
        marginLeft="5px"
        marginBottom={'15px'}
        marginTop={'15px'}
        align="left"
      >
        Votre lien avec le bénéficiaire
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="referralLink-label">
          {formik.touched.referralLink && formik.errors.referralLink ? (
            <span style={{ color: theme.palette.error.main }}>
              👨‍👩‍👧‍👦 Vous êtes:
            </span>
          ) : (
            '👨‍👩‍👧‍👦 Vous êtes:'
          )}
        </InputLabel>
        <Select
          id="referralLink"
          name="referralLink"
          labelId="referralLink-label"
          label="👨‍👩‍👧‍👦 Votre lien avec le bénéficiaire"
          value={formik.values.referralLink}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.referralLink && Boolean(formik.errors.referralLink)
          }
        >
          <MenuItem value={'beneficiaire'}>Je suis le bénéficiaire</MenuItem>
          <MenuItem value={'parent'}>Un Parent</MenuItem>
          <MenuItem value={'enfant'}>Un enfant</MenuItem>
          <MenuItem value={'pro'}>Un professionnel</MenuItem>
          <MenuItem value={'autres'}>Autres</MenuItem>
        </Select>
        <FormHelperText>
          <span style={{ color: theme.palette.error.main }}>
            {formik.touched.referralLink && formik.errors.referralLink}
          </span>
        </FormHelperText>
      </FormControl>
    </Grid>
  )
}
