import styled from '@emotion/styled'

export const SwitchRoot = styled.span`
  width: ${({ yesValue }: { yesValue: string; noValue: string }) =>
    String(yesValue.length + 66)}px;
  height: 28px;

  display: inline-block;
  position: relative;

  border-radius: ${({ theme }) => theme.extend.radius.xs};
`

export const SwitchInput = styled.input`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  margin: 0;
  cursor: pointer;
`

export const SwitchThumb = styled.span`
  width: 20px;
  height: 20px;

  position: absolute;
  display: block;
  top: ${({ theme }) => theme.extend.getSpacing(1)};
  left: ${({ theme }) => theme.extend.getSpacing(1)};

  background-color: ${({ theme }) => theme.extend.palette.grey.medium};
  border-radius: 2px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &.checked {
    background-color: ${({ theme }) => theme.palette.common.white};
    transform: translateX(
      ${({ yesValue }: { yesValue: string; noValue: string }) =>
        String(yesValue.length + 38)}px
    );
  }
`

export const SwitchTrack = styled('span')`
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.extend.palette.grey.light};
  border-radius: ${({ theme }) => theme.extend.radius.xs};

  display: block;

  font-size: ${({ theme }) => theme.extend.fontSize.xs};
  font-weight: ${({ theme }) => theme.extend.fontWeight.bold};
  font-family: ${({ theme }) => theme.extend.fontFamily.secondary};

  &.checked {
    background-color: ${({ theme }) => theme.palette.primary.main};
    &::before {
      position: absolute;
      left: 15%;
      top: 6%;
      content: '${({ yesValue }: { yesValue: string; noValue: string }) =>
        yesValue || 'OUI'}';
      color: ${({ theme }) => theme.palette.common.white};
    }
  }

  &::before {
    position: absolute;
    right: 10%;
    top: 6%;
    content: '${({ noValue }: { yesValue: string; noValue: string }) =>
      noValue || 'NON'}';
    color: ${({ theme }) => theme.extend.palette.grey.medium};
  }
`
