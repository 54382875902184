/* eslint-disable react/prop-types */
import { FormikProps } from 'formik'
import { useState } from 'react'
import Keycloak from 'keycloak-js'
import { AnnounceAutcomplete } from 'components/Form'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'

interface UrssafPayerAutocompleteProps {
  form: FormikProps<any>
  keycloak?: Keycloak
  onChange(value: string): void
}

export const UrssafPayerAutocomplete: React.FC<
  UrssafPayerAutocompleteProps
> = ({ form, onChange }) => {
  const [options, setOptions] = useState<{ id: string; label: string }[]>([])

  const handleChange = (announce: any) => {
    if (announce) {
      setOptions([
        {
          id: announce.beneficiary.id,
          label: `Beneficiaire - ${announce.beneficiary?.person?.firstName} ${announce.beneficiary?.person?.lastName}`
        }
        // {
        //   id: announce.beneficiary.family.id,
        //   label: `Référent ${announce.beneficiary.family.person.firstName} ${announce.beneficiary.family.person.lastName}`
        // }
      ])
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <AnnounceAutcomplete
          searchMode="reference"
          id="announce"
          label="Chercher une annonce par référence"
          form={form}
          postOnChangeEvent={handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Payeur</InputLabel>
          <Select
            disabled={options.length === 0}
            onChange={e => onChange(e.target.value as string)}
            label="person"
          >
            {options.map(person => (
              <MenuItem key={person.id} value={person.id}>
                {person.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
