export const CountryInseeCode = new Map<string, string>([
  ['99100', 'FRANCE'],
  ['99101', 'DANEMARK / FEROE (ILES)'],
  ['99102', 'ISLANDE'],
  ['99103', 'NORVEGE / SVALBARD et ILE JAN MAYEN / BOUVET (ILE)'],
  ['99104', 'SUEDE'],
  ['99105', 'FINLANDE'],
  ['99106', 'ESTONIE'],
  ['99107', 'LETTONIE'],
  ['99108', 'LITUANIE'],
  ['99109', 'ALLEMAGNE'],
  ['99110', 'AUTRICHE'],
  ['99111', 'BULGARIE'],
  ['99112', 'HONGRIE'],
  ['99113', 'LIECHTENSTEIN'],
  ['99114', 'ROUMANIE'],
  ['99115', 'TCHECOSLOVAQUIE'],
  ['99116', 'TCHEQUIE'],
  ['99117', 'SLOVAQUIE'],
  ['99118', 'BOSNIE-HERZEGOVINE'],
  ['99119', 'CROATIE'],
  ['99120', 'MONTENEGRO'],
  ['99121', 'SERBIE'],
  ['99122', 'POLOGNE'],
  ['99123', 'RUSSIE'],
  ['99124', "TURQUIE D'EUROPE"],
  ['99125', 'ALBANIE'],
  ['99126', 'GRECE'],
  ['99127', 'ITALIE'],
  ['99128', 'SAINT-MARIN'],
  ['99129', 'VATICAN", ou SAINT-SIEGE'],
  ['99130', 'ANDORRE'],
  ['99131', 'BELGIQUE'],
  ['99132', 'ROYAUME-UNI / GUERNESEY / JERSEY / MAN (ILE)'],
  ['99133', 'GIBRALTAR'],
  ['99134', 'ESPAGNE'],
  ['99135', 'PAYS-BAS / ARUBA'],
  ['99136', 'IRLANDE", ou EIRE'],
  ['99137', 'LUXEMBOURG'],
  ['99138', 'MONACO'],
  ['99139', 'PORTUGAL'],
  ['99140', 'SUISSE'],
  ['99141', 'REPUBLIQUE DEMOCRATIQUE ALLEMANDE'],
  ['99142', "REPUBLIQUE FEDERALE D'ALLEMagne"],
  ['99144', 'MALTE'],
  ['99145', 'SLOVENIE'],
  ['99148', 'BIELORUSSIE'],
  ['99151', 'MOLDAVIE'],
  ['99155', 'UKRAINE'],
  ['99156', 'MACEDOINE DU NORD'],
  ['99157', 'KOSOVO'],
  ['99201', 'ARABIE SAOUDITE'],
  ['99202', 'YEMEN (REPUBLIQUE ARABE DU)'],
  ['99203', 'IRAQ'],
  ['99204', 'IRAN'],
  ['99205', 'LIBAN'],
  ['99206', 'SYRIE'],
  ['99207', 'ISRAEL'],
  ['99208', 'TURQUIE'],
  ['99209', 'SIBERIE'],
  ['99210', 'TURKESTAN RUSSE'],
  ['99211', 'KAMTCHATKA'],
  ['99212', 'AFGHANISTAN'],
  ['99213', 'PAKISTAN'],
  ['99214', 'BHOUTAN'],
  ['99215', 'NEPAL'],
  ['99216', 'CHINE'],
  ['99217', 'JAPON'],
  ['99218', 'MANDCHOURIE'],
  ['99219', 'THAILANDE'],
  ['99220', 'PHILIPPINES'],
  ['99221', 'POSSESSIONS BRITANNIQUES AU PROCHE-ORIENT'],
  ['99222', 'JORDANIE'],
  ['99223', 'GOA'],
  ['99224', 'BIRMANIE'],
  ['99225', 'BRUNEI'],
  ['99226', 'SINGAPOUR'],
  ['99227', 'MALAISIE'],
  ['99228', 'ETATS MALAIS NON FEDERES'],
  ['99229', 'MALDIVES'],
  ['99230', 'HONG-KONG'],
  ['99231', 'INDONESIE'],
  ['99232', 'MACAO'],
  ['99233', 'YEMEN DEMOCRATIQUE'],
  ['99234', 'CAMBODGE'],
  ['99235', 'SRI LANKA'],
  ['99236', 'TAIWAN'],
  ['99237', 'COREE'],
  ['99238', 'COREE (REPUBLIQUE POPULAIRE DEMOCRATIQUE DE)'],
  ['99239', 'COREE (REPUBLIQUE DE)'],
  ['99240', 'KOWEIT'],
  ['99241', 'LAOS'],
  ['99242', 'MONGOLIE'],
  ['99243', 'VIET NAM'],
  ['99244', 'VIET NAM DU NORD'],
  ['99245', 'VIET NAM DU SUD'],
  ['99246', 'BANGLADESH'],
  ['99247', 'EMIRATS ARABES UNIS'],
  ['99248', 'QATAR'],
  ['99249', 'BAHREIN'],
  ['99250', 'OMAN'],
  ['99251', 'YEMEN'],
  ['99252', 'ARMENIE'],
  ['99253', 'AZERBAIDJAN'],
  ['99254', 'CHYPRE'],
  ['99255', 'GEORGIE'],
  ['99256', 'KAZAKHSTAN'],
  ['99257', 'KIRGHIZISTAN'],
  ['99258', 'OUZBEKISTAN'],
  ['99259', 'TADJIKISTAN'],
  ['99260', 'TURKMENISTAN'],
  ['99261', 'PALESTINE (Etat de)'],
  ['99262', 'TIMOR ORIENTAL'],
  ['99301', 'EGYPTE'],
  ['99302', 'LIBERIA'],
  ['99303', 'AFRIQUE DU SUD'],
  ['99304', 'GAMBIE'],
  ['99305', 'CAMEROUN ET TOGO'],
  ['99306', 'SAINTE HELENE", ASCENSION ET TRISTAN DA CUNHA'],
  ['99307', 'SOUDAN ANGLO-EGYPTIEN", KENYA", OUGANDA'],
  ['99308', "ZANZIBAR / OCEAN INDIEN (TERRITOIRE BRITANNIQUE DE L')"],
  ['99309', 'TANZANIE'],
  ['99310', 'ZIMBABWE'],
  ['99311', 'NAMIBIE'],
  ['99312', 'CONGO (REPUBLIQUE DEMOCRATIQUE)'],
  ['99313', "CANARIES (ILES) / PRESIDES / PROVINCES ESPAGNOLES D'AFRIQUE"],
  ['99314', 'GUINEE EQUATORIALE'],
  ['99315', 'ETHIOPIE'],
  ['99316', 'LIBYE'],
  ['99317', 'ERYTHREE'],
  ['99318', 'SOMALIE'],
  ['99319', 'ACORES", MADERE'],
  ['99320', "ILES PORTUGAISES DE L'OCEAN INDIEN"],
  ['99321', 'BURUNDI'],
  ['99322', 'CAMEROUN'],
  ['99323', 'CENTRAFRICAINE (REPUBLIQUE)'],
  ['99324', 'CONGO'],
  ['99325', 'TANGER'],
  ['99326', "COTE D'IVOIRE"],
  ['99327', 'BENIN'],
  ['99328', 'GABON'],
  ['99329', 'GHANA'],
  ['99330', 'GUINEE'],
  ['99331', 'BURKINA'],
  ['99332', 'KENYA'],
  ['99333', 'MADAGASCAR'],
  ['99334', 'MALAWI'],
  ['99335', 'MALI'],
  ['99336', 'MAURITANIE'],
  ['99337', 'NIGER'],
  ['99338', 'NIGERIA'],
  ['99339', 'OUGANDA'],
  ['99340', 'RWANDA'],
  ['99341', 'SENEGAL'],
  ['99342', 'SIERRA LEONE'],
  ['99343', 'SOUDAN'],
  ['99344', 'TCHAD'],
  ['99345', 'TOGO'],
  ['99346', 'ZAMBIE'],
  ['99347', 'BOTSWANA'],
  ['99348', 'LESOTHO'],
  ['99349', 'SOUDAN DU SUD'],
  ['99350', 'MAROC'],
  ['99351', 'TUNISIE'],
  ['99352', 'ALGERIE'],
  ['99389', 'SAHARA OCCIDENTAL'],
  ['99390', 'MAURICE'],
  ['99391', 'ESWATINI'],
  ['99392', 'GUINEE-BISSAU'],
  ['99393', 'MOZAMBIQUE'],
  ['99394', 'SAO TOME-ET-PRINCIPE'],
  ['99395', 'ANGOLA'],
  ['99396', 'CAP-VERT'],
  ['99397', 'COMORES'],
  ['99398', 'SEYCHELLES'],
  ['99399', 'DJIBOUTI'],
  ['99401', 'CANADA'],
  ['99402', 'TERRE-NEUVE'],
  ['99403', 'LABRADOR'],
  ['99404', 'ETATS-UNIS'],
  ['99405', 'MEXIQUE'],
  ['99406', 'COSTA RICA'],
  ['99407', 'CUBA'],
  ['99408', 'DOMINICAINE (REPUBLIQUE)'],
  ['99409', 'GUATEMALA'],
  ['99410', 'HAITI'],
  ['99411', 'HONDURAS'],
  ['99412', 'NICARAGUA'],
  ['99413', 'PANAMA'],
  ['99414', 'EL SALVADOR'],
  ['99415', 'ARGENTINE'],
  ['99416', 'BRESIL'],
  ['99417', 'CHILI'],
  ['99418', 'BOLIVIE'],
  ['99419', 'COLOMBIE'],
  ['99420', 'EQUATEUR'],
  ['99421', 'PARAGUAY'],
  ['99422', 'PEROU'],
  ['99423', 'URUGUAY'],
  ['99424', 'VENEZUELA'],
  [
    '99425',
    'ANGUILLA / BERMUDES / CAIMANES (ILES) / MONTSERRAT /TERRITOIRES DU ROYAUME-UNI AUX ANTILLES / TURKS ET CAIQUES (ILES) / VIERGES BRITANNIQUES (ILES)'
  ],
  ['99426', 'JAMAIQUE'],
  [
    '99427',
    "MALOUINES, OU FALKLAND (ILES) / TERR. DU ROYAUME-UNI DANS L'ATLANTIQUE SUD / GEORGIE DU SUD ET LES ILES SANDWICH DU SUD"
  ],
  ['99428', 'GUYANA'],
  ['99429', 'BELIZE'],
  ['99430', 'GROENLAND'],
  ['99431', 'ANTILLES NEERLANDAISES'],
  [
    '99432',
    "PORTO RICO / TERR. DES ETATS-UNIS D'AMERIQUE EN AMERIQUE / VIERGES DES ETATS-UNIS (ILES)"
  ],
  ['99433', 'TRINITE-ET-TOBAGO'],
  ['99434', 'BARBADE'],
  ['99435', 'GRENADE'],
  ['99436', 'BAHAMAS'],
  ['99437', 'SURINAME'],
  ['99438', 'DOMINIQUE'],
  ['99439', 'SAINTE-LUCIE'],
  ['99440', 'SAINT-VINCENT-ET-LES GRENADINES'],
  ['99441', 'ANTIGUA-ET-BARBUDA'],
  ['99442', 'SAINT-CHRISTOPHE-ET-NIEVES'],
  ['99443', 'BONAIRE", SAINT EUSTACHE ET SABA'],
  ['99444', 'CURAÇAO'],
  ['99445', 'SAINT-MARTIN (PARTIE NEERLANDAISE)'],
  [
    '99501',
    'AUSTRALIE / CHRISTMAS (ILE) / COCOS ou KEELING (ILES) / HEARD ET MACDONALD (ILES) / NORFOLK (ILE)'
  ],
  ['99502', 'NOUVELLE-ZELANDE / TOKELAU / COOK (ILES) / NIUE'],
  ['99503', 'PITCAIRN (ILE)'],
  ['99504', 'HAWAII (ILES)'],
  [
    '99505',
    "GUAM, MARIANNES DU NORD (ILES) / SAMOA AMERICAINES / TERR. DES ETATS-UNIS D'AMERIQUE EN OCEANIE"
  ],
  ['99506', 'SAMOA OCCIDENTALES'],
  ['99507', 'NAURU'],
  ['99508', 'FIDJI'],
  ['99509', 'TONGA'],
  ['99510', 'PAPOUASIE-NOUVELLE-GUINEE'],
  ['99511', 'TUVALU'],
  ['99512', 'SALOMON (ILES)'],
  ['99513', 'KIRIBATI'],
  ['99514', 'VANUATU'],
  ['99515', 'MARSHALL (ILES)'],
  ['99516', 'MICRONESIE (ETATS FEDERES DE)'],
  ['99517', 'PALAOS (ILES)']
])
