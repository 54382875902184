import { Autocomplete, TextField } from '@mui/material'

export default function OrgAutocomplete({
  orgs,
  setSelectedOrg,
}: any) {
  return (
    <Autocomplete
      freeSolo
      fullWidth
      options={orgs.map((org: any) => `${org.name} - ${org.id}`)}
      onChange={(event, value) => {
        setSelectedOrg(
          orgs.find((org: any) => `${org.name} - ${org.id}` === value) || {}
        )
      }}
      renderInput={params => (
        <TextField {...params} fullWidth label={'Etablissement référent'} />
      )}
    />
  )
}
