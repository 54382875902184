import { Lead, Schedule } from 'types/entity'
import { getApiClient } from './network'
import { CreateScheduleDto } from 'types/dto'

// export const getSchedulesEditableByLeadId = async (
//   id: string
// ): Promise<boolean> => {
//   const { data: isEditable } = await forestgetApiClient().get<boolean>(
//     `/beneficiary/${id}/isScheduleEditable`
//   )

//   return isEditable
// }

export const getAllSchedulesByLeadId = async (
  id: string,
  apiKey?: string
): Promise<Schedule[]> => {
  const { data: schedules } = await getApiClient(apiKey).get<Schedule[]>(
    `/leads/${id}/schedules`
  )

  return schedules
}

export const getLeadById = async (
  id: string,
  apiKey?: string
): Promise<Lead> => {
  const { data: lead } = await getApiClient(apiKey).get<Lead>(`/leads/${id}`)

  return lead
}

export const createScheduleWithLeadId = async (
  leadId: string,
  schedule: CreateScheduleDto,
  apiKey?: string
): Promise<void> => {
  return getApiClient(apiKey).post(`/leads/${leadId}/schedules`, schedule)
}

export let lead: Lead
