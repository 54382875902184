import styled from '@emotion/styled'

export const SwitchRoot = styled.span`
  width: 66px;
  height: 28px;

  display: inline-block;
  position: relative;

  background-color: ${({ theme }) => theme.extend.palette.grey.medium};
  border-radius: ${({ theme }) => theme.extend.radius.xs};
`

export const SwitchInput = styled.input`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  margin: 0;
  cursor: pointer;
`

export const SwitchThumb = styled.span`
  width: 20px;
  height: 20px;

  position: absolute;
  display: block;
  top: ${({ theme }) => theme.extend.getSpacing(1)};
  left: ${({ theme }) => theme.extend.getSpacing(1)};

  background-color: ${({ theme }) => theme.extend.palette.grey.white};
  border-radius: 2px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &.checked {
    background-color: ${({ theme }) => theme.palette.common.white};
    transform: translateX(38px);
  }
`

export const SwitchTrack = styled('span')`
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.extend.palette.grey.main};
  border-radius: ${({ theme }) => theme.extend.radius.xs};

  display: block;

  font-size: ${({ theme }) => theme.extend.fontSize.xs};
  font-weight: ${({ theme }) => theme.extend.fontWeight.bold};
  font-family: ${({ theme }) => theme.extend.fontFamily.secondary};

  &.checked {
    background-color: ${({ theme }) => theme.palette.primary.main};
    &::before {
      position: absolute;
      left: 15%;
      top: 30%;
      content: 'OUI';
      color: ${({ theme }) => theme.palette.common.white};
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    }
  }

  &::before {
    position: absolute;
    right: 10%;
    top: 30%;
    content: 'NON';
    color: ${({ theme }) => theme.extend.palette.grey.white};
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
`
