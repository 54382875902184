import { Alert, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { ContractTypePicker } from 'components/Calendar/organisms'
import { Field } from 'formik'
import React from 'react'
import { DaysPicker } from 'components/Calendar/organisms/DaysPicker'
import { CalendarTimePicker } from 'components/Calendar/organisms/CalendarTimePicker'
import { Row } from './Planning.style'
import { isHourNightshift } from 'utils/tools'
import { ContractType } from 'types/enum'

type DialogModalProps = {
  canSubmit: boolean
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  setIsCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  startHour?: string
  endHour?: string
  contractType: ContractType | undefined
}

const EditableCalendarDialogModal = ({
  canSubmit,
  handleSubmit,
  setIsCreateModalOpen,
  startHour,
  endHour,
  contractType
}: DialogModalProps) => {
  const isNightShift = isHourNightshift({
    startHour: startHour || '',
    endHour: endHour || ''
  })

  if (contractType === ContractType.MIX) {
    contractType = undefined
  }

  return (
    <>
      <DialogTitle>Ajouter un créneau</DialogTitle>
      <DialogContent>
        <Row container spacing={3}>
          <Grid item md={8} sm={8} xs={12}>
            <Field
              name={`days`}
              component={DaysPicker}
              label="Jour"
              withError={true}
              required={true}
            />
            <Field
              name={`startHour`}
              component={CalendarTimePicker}
              label="Heure de début"
              withError={true}
              required={true}
            />
          </Grid>
        </Row>

        <Row container spacing={3}>
          <Grid item md={6} sm={6} xs={12}>
            <Field
              name={`endHour`}
              component={CalendarTimePicker}
              label="Heure de fin"
              withError={true}
              required={true}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}></Grid>
        </Row>
        <Row container spacing={3}>
          <Grid item md={8} sm={8} xs={12}>
            <Field
              name={`contractType`}
              component={ContractTypePicker}
              label="Type de contrat"
              withError={true}
              contractType={contractType}
            />
          </Grid>
        </Row>
        <Row container spacing={3}>
          <Grid item md={8} sm={8} xs={12}>
            {isNightShift && (
              <Alert severity="warning">
                Etes-vous sûr de vouloir créer un créneau de nuit ?
              </Alert>
            )}
          </Grid>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsCreateModalOpen(false)
          }}
        >
          Annuler
        </Button>
        <Button
          type="submit"
          disabled={!canSubmit}
          onClick={() => {
            handleSubmit()
          }}
        >
          Créer
        </Button>
      </DialogActions>
    </>
  )
}

export default EditableCalendarDialogModal
