import styled from '@emotion/styled'

export const Container = styled.div``
export const Hours = styled.div`
  font-size: 0.85em;
  font-weight: 700;
`
export const Title = styled.div``
export const DeleteButton = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  background: transparent;
  border: none;
  cursor: pointer;
`
