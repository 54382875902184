import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { isValidIBAN } from 'ibantools'
import { FormikProps } from 'formik'

// Function to format IBAN with spaces
const formatIBAN = (value: string) => {
  return value
    .replace(/[^A-Z0-9]/gi, '')
    .replace(/(.{4})/g, '$1 ')
    .trim()
}

interface IBANInputProps {
  id: string
  label?: string
  form: FormikProps<any>
}

export const IBANInput: React.FC<IBANInputProps> = ({ id, label, form }) => {
  const [iban, setIban] = useState('')
  const [error, setError] = useState(false)

  const handleChange = (event: any) => {
    const rawValue = event.target.value.replace(/\s/g, '')
    const formattedValue = formatIBAN(rawValue)

    setIban(formattedValue)
    setError(!isValidIBAN(rawValue))

    form.setFieldValue(id, rawValue)
  }

  useEffect(() => {
    const formattedValue = formatIBAN(form.values[id])

    setIban(formattedValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values[id]])

  return (
    <TextField
      id={id}
      label={label ?? 'IBAN'}
      value={iban}
      onChange={handleChange}
      error={error}
      helperText={error ? 'IBAN invalide' : ''}
      variant="outlined"
      fullWidth
      InputLabelProps={{
        shrink: true
      }}
    />
  )
}
