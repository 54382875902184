import axios from 'axios'
import moment from 'moment'

export const getVacancy = (id: string): any =>
  axios
    .get(
      `${process.env.REACT_APP_LOAD_BALANCER_PROTOCOL}://${process.env.REACT_APP_LOAD_BALANCER_ENDPOINT}:${process.env.REACT_APP_VACANCIES_PORT}/vacancies/${id}`
    )
    .then(data => data.data)

export const getEstablishment = (id: string): any =>
  axios
    .get(
      `${process.env.REACT_APP_LOAD_BALANCER_PROTOCOL}://${process.env.REACT_APP_LOAD_BALANCER_ENDPOINT}:${process.env.REACT_APP_ESTABLISHMENTS_PORT}/establishments/${id}`
    )
    .then(data => data.data)

// eslint-disable-next-line max-params
export const getCompleteList = async <Type>(
  url: string,
  offset = 0,
  limit = 100,
  current: Array<Type>
): Promise<Type[]> =>
  axios
    .get(
      url + (url.indexOf('q') ? '&' : 'q') + `offset=${offset}&limit=${limit}`
    )
    .then(data => {
      if (!data.data.length) {
        return Promise.resolve(current)
      }

      const newData = [...current, ...data.data]
      const newLimit = offset + limit

      return getCompleteList(url, newLimit, limit, newData)
    })

export const getEstablishments = async (): Promise<any[]> =>
  getCompleteList<any>(
    `${process.env.REACT_APP_LOAD_BALANCER_PROTOCOL}://${process.env.REACT_APP_LOAD_BALANCER_ENDPOINT}:${process.env.REACT_APP_ESTABLISHMENTS_PORT}/establishments?status=Client,CLIENT,En Test,EN TEST`,
    0,
    100,
    []
  )

export const searchCareGivers = async (phone: string): Promise<any[]> =>
  await axios
    .get(
      `${process.env.REACT_APP_LOAD_BALANCER_PROTOCOL}://${process.env.REACT_APP_LOAD_BALANCER_ENDPOINT}:${process.env.REACT_APP_CARE_GIVERS_PORT}/care-givers?phone=${phone}&onboardingStatus=POOL`
    )
    .then(({ data }) => {
      if (!data || data.length === 0) {
        return (
          axios
            .get(
              `${process.env.REACT_APP_LOAD_BALANCER_PROTOCOL}://${process.env.REACT_APP_LOAD_BALANCER_ENDPOINT}:${process.env.REACT_APP_CARE_GIVERS_PORT}/care-givers?phone=${phone}`
            )
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .then(({ data }) => data)
        )
      }

      return data
    })

const getCareGiver = async (careGiverId: string) => {
  const careGiver = await axios
    .get(
      `${process.env.REACT_APP_LOAD_BALANCER_PROTOCOL}://${process.env.REACT_APP_LOAD_BALANCER_ENDPOINT}:${process.env.REACT_APP_CARE_GIVERS_PORT}/care-givers/${careGiverId}`
    )
    .then(({ data }) => data)
    .catch(() => ({}))

  return careGiver
}

export const getVacancyMatchings = async (
  careGivers: Map<string, any>,
  vacancy?: any,
  careGiverId?: string,
  vacancyId?: string
): Promise<any[]> => {
  const matchings = vacancy
    ? vacancy.matchings
    : await axios
        .get(
          `${process.env.REACT_APP_LOAD_BALANCER_PROTOCOL}://${process.env.REACT_APP_LOAD_BALANCER_ENDPOINT}:${process.env.REACT_APP_VACANCIES_PORT}/vacancy-matchings`,
          {
            params: {
              ...(careGiverId && { careGiverId }),
              ...(vacancyId && { vacancyId })
            }
          }
        )
        .then(({ data }) => data)

  const newMatchings = []

  for (const match of matchings) {
    const careGiver = careGivers.has(match.careGiverId)
      ? careGivers.get(match.careGiverId)
      : await getCareGiver(match.careGiverId)

    careGivers.set(match.careGiverId, careGiver)
    newMatchings.push({ ...match, careGiver })
  }

  if (newMatchings.length) {
    newMatchings.sort((a: any, b: any) => {
      const scoreDiff = b.score - a.score

      if (scoreDiff !== 0) {
        return scoreDiff
      }

      const aPoolScore = a.careGiver.onboardingStatus === 'POOL' ? 1 : 0
      const bPoolScore = b.careGiver.onboardingStatus === 'POOL' ? 1 : 0

      if (aPoolScore !== bPoolScore) {
        return bPoolScore - aPoolScore
      }

      return a.careGiver.firstName > b.careGiver.firstName ? 1 : -1
    })
  }

  return [newMatchings, careGivers]
}

export const getVacanciesByEstablishmentId = async (
  establishmentId: string
): Promise<any[]> => {
  const vacancies = await axios.get(
    `${process.env.REACT_APP_LOAD_BALANCER_PROTOCOL}://${process.env.REACT_APP_LOAD_BALANCER_ENDPOINT}:${process.env.REACT_APP_VACANCIES_PORT}/vacancies?establishmentId=${establishmentId}&status=INITIAL`
  )

  let careGivers = new Map<string, any>()
  const vacanciesWithMatchings = []

  for (const vac of vacancies.data) {
    const [matchings, newCareGivers] = await getVacancyMatchings(
      careGivers,
      vac
    )

    careGivers = newCareGivers

    vacanciesWithMatchings.push({ ...vac, matchings })
  }

  vacanciesWithMatchings.sort(
    (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix()
  )

  return Promise.resolve(vacanciesWithMatchings)
}

export const getEstablishmentsByCareGiverId = async (
  careGiverId: string
): Promise<any[]> => {
  const careGivers = new Map<string, any>()
  const [matchings] = await getVacancyMatchings(
    careGivers,
    undefined,
    careGiverId
  )
  const vacancyIds = matchings.reduce(
    (acc: any, { vacancyId }: { vacancyId: string }) => {
      if (acc.indexOf(vacancyId) !== -1) {
        return acc
      }

      return [...acc, vacancyId]
    },
    []
  )
  const vacancies = []

  for (const id of vacancyIds) {
    const vacancy = await getVacancy(id)

    vacancies.push(vacancy)
  }
  const establishmentIds = vacancies.reduce((acc, { establishmentId }) => {
    if (acc.indexOf(establishmentId) !== -1) {
      return acc
    }

    return [...acc, establishmentId]
  }, [])
  const establishments = await Promise.all(
    establishmentIds.map((id: string) => getEstablishment(id))
  )

  return establishments
}

export const setRequestSend = async (matchingId: string) => {
  await axios.post(
    `${process.env.REACT_APP_LOAD_BALANCER_PROTOCOL}://${process.env.REACT_APP_LOAD_BALANCER_ENDPOINT}:${process.env.REACT_APP_VACANCIES_PORT}/vacancy-matchings/${matchingId}/request-send`,
    {}
  )
}

export const setWaiting = async (matchingId: string) => {
  await axios.post(
    `${process.env.REACT_APP_LOAD_BALANCER_PROTOCOL}://${process.env.REACT_APP_LOAD_BALANCER_ENDPOINT}:${process.env.REACT_APP_VACANCIES_PORT}/vacancy-matchings/${matchingId}/set-waiting`,
    {}
  )
}

export const setInitial = async (matchingId: string) => {
  await axios.post(
    `${process.env.REACT_APP_LOAD_BALANCER_PROTOCOL}://${process.env.REACT_APP_LOAD_BALANCER_ENDPOINT}:${process.env.REACT_APP_VACANCIES_PORT}/vacancy-matchings/${matchingId}/set-initial`,
    {}
  )
}

export const getCareGiverMatchingVacancies = async (
  careGiverId?: string
): Promise<any[][]> => {
  const matchings = await axios
    .get(
      `${process.env.REACT_APP_LOAD_BALANCER_PROTOCOL}://${process.env.REACT_APP_LOAD_BALANCER_ENDPOINT}:${process.env.REACT_APP_VACANCIES_PORT}/vacancy-matchings`,
      {
        params: {
          ...(careGiverId && { careGiverId }),
          vacancyStartDateGte: moment().subtract(1, 'month').format()
        }
      }
    )
    .then(data => data.data)

  const establishments: any[] = []
  const vacancies: any[] = []

  for (const matching of matchings) {
    const vacancy = { ...matching.vacancy, currentCareGiverMatching: matching }

    const establishment: any =
      establishments.find(({ id }) => vacancy.establishmentId === id) ||
      (await getEstablishment(vacancy.establishmentId))

    vacancies.push({ ...vacancy, establishment })
    if (!establishments.find(({ id }) => vacancy.establishmentId === id)) {
      establishments.push(establishment)
    }
  }

  establishments.sort((a, b) => (a.name > b.name ? 1 : -1))

  // DEBUG
  // const vacsDebug = vacancies.map((vac) => ({ ...vac, qualification: 'AS' }))
  // vacancies.push(...vacsDebug)
  // DEBUG

  const groups = vacancies.reduce((acc, vacancy) => {
    const key = `${vacancy.establishmentId}_${vacancy.qualification}_${vacancy.fees}`

    if (acc[key]) {
      acc[key].vacancies.push(vacancy)

      return acc
    }

    acc[key] = {
      key,
      qualification: vacancy.qualification,
      establishment: vacancy.establishment,
      vacancies: [vacancy]
    }

    return acc
  }, {})

  return [vacancies, establishments, groups]
}
