import { useEffect } from 'react'
import Keycloak from 'keycloak-js'

export const useKeycloakAuth = (keycloak: Keycloak | undefined) => {
  useEffect(() => {
    if (!keycloak) {
      return
    }

    if (!keycloak.authenticated) {
      window.location.assign(keycloak.createLoginUrl())
    }
  }, [keycloak])
}
